import React, {useCallback, useEffect, useState} from 'react';
import {Navigate, useNavigate, useParams} from "react-router";
import {useAuth} from "./login-signup-page";
import "../stylesheet/profile-page.css"
import defaultPicture from "../media/default-picture.jpg";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ArtContainer, FormatUserPostText, ImagePopup} from "./home-page";
import {useDropzone} from "react-dropzone";
import {useNotifications} from "../components/navbar";
import {DefaultPage} from "./default-page";
import {Link} from "react-router-dom";

const GetRelationship = async (handle, other_handle, callback) => {
    const res = await fetch(`https://sketchflow.io/api/user/${handle}/relationship/${other_handle}`);

    const resData = await res.json();
    if (res.ok) {
        callback(resData.relationship);
    } else {
        callback("none")
    }
}

export const GetProfilePicture = async (handle, callback) => {
    await GetProfileDetails(handle, (details) => {
        if (details === null) {
            callback(null)
            return
        }
        callback(details.picture_url)
    })
}

export const GetProfileDetails = async (handle, callback) => {
    const res = await fetch(`https://sketchflow.io/api/user/${handle}/profile`);

    const resData = await res.json();
    if (res.ok) {
        if (resData.picture_url === "") {
            resData.picture_url = defaultPicture;
        }
        callback(resData);
    } else {
        callback(null)
    }
}

const GetFollowing = async (handle, callback) => {
    const res = await fetch(`https://sketchflow.io/api/user/${handle}/following`);

    const resData = await res.json();
    if (res.ok) {
        callback(resData.following);
    } else {
        callback({})
    }
}

const GetFollowers = async (handle, callback) => {
    const res = await fetch(`https://sketchflow.io/api/user/${handle}/followers`);

    const resData = await res.json();
    if (res.ok) {
        callback(resData.followers);
    } else {
        callback({})
    }
}

const GetPosts = async (handle, filter, callback) => {
    const res = await fetch(`https://sketchflow.io/api/user/${handle}/posts?filter=${filter}`);

    const resData = await res.json();
    if (res.ok) {
        callback(resData.posts);
    } else {
        callback({})
    }
}

const Follow = async (handle, other_handle, onSuccess) => {
    const res = await fetch(`https://sketchflow.io/api/user/${handle}/relationship/${other_handle}`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('jwt')
        },
        body: JSON.stringify({
            relationship: "following"
        })
    });

    await res.json();
    if (res.ok) {
        onSuccess();
    }
}

const Unfollow = async (handle, other_handle, onSuccess) => {
    const res = await fetch(`https://sketchflow.io/api/user/${handle}/relationship/${other_handle}`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('jwt')
        },
        body: JSON.stringify({
            relationship: "none"
        })
    });

    await res.json();
    if (res.ok) {
        onSuccess();
    }
}

const FollowButton = ({profile_handle}) => {
    const {handle} = useAuth();
    const [loading, setLoading] = useState(true);
    const [following, setFollowing] = useState(false);
    const [friends, setFriends] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        if (handle !== "") {
            GetRelationship(handle, profile_handle, (result) => {
                setLoading(false)
                setFollowing(result === "following")
                setFriends(result === "friends")
            })
        }
    }, [handle])

    if (loading) {
        return (<button className={"secondary-button profile-follow-button"}>
            <FontAwesomeIcon icon={solid("spinner")} spin style={{color: "var(--font-colour)"}}/>
            <span> Loading</span>
        </button>)
    }
    if (following) {
        return (<>
            <button onClick={() => Unfollow(handle, profile_handle, () => setFollowing(false))}
                    className={"secondary-button profile-follow-button"}>
                <FontAwesomeIcon icon={solid("check")} style={{color: "var(--font-colour)"}}/>
                <span> Following</span>
            </button>

            <button className={"secondary-button profile-follow-button"} style={{marginLeft: "10px"}}
                    onClick={() => navigate(`/inbox?conversation=${profile_handle}`)}>
                <FontAwesomeIcon icon={solid("envelope")} style={{color: "var(--font-colour)"}}/>
                <span> Message</span>
            </button>

        </>)
    } else if (friends) {
        return (<>
            <button onClick={() => Unfollow(handle, profile_handle, () => {
                setFollowing(false)
                setFriends(false)
            })}
                    className={"secondary-button profile-follow-button"}>
                <FontAwesomeIcon icon={solid("user-group")} style={{color: "var(--font-colour)"}}/>
                <span> Friends</span>
            </button>
            <button className={"secondary-button profile-follow-button"} style={{marginLeft: "10px"}}
                    onClick={() => navigate(`/inbox?conversation=${profile_handle}`)}>
                <FontAwesomeIcon icon={solid("envelope")} style={{color: "var(--font-colour)"}}/>
                <span> Message</span>
            </button>
        </>)
    } else {
        return (<button onClick={() => Follow(handle, profile_handle, () => setFollowing(true))}
                        className={"primary-button profile-follow-button"}>
            <FontAwesomeIcon icon={solid("plus")} style={{color: "white"}}/>
            <span> Follow</span>
        </button>)
    }
}

const ChangePicturePopup = ({onSuccess, onCancel}) => {
    const onDrop = useCallback((files) => {
        onSuccess(files[0]);
    }, [onSuccess]);


    const {getRootProps, getInputProps, isDragActive, open} = useDropzone({
        onDrop,
        noClick: true,
        accept: {'image/*': []},
        maxFiles: 1,
        multiple: false,
        maxSize: (8 * 1024 * 1024)
    });

    return (
        <>
            <div className={"profile-picture-upload-blackout"} onClick={onCancel}/>
            <div className={"profile-picture-upload-container"}>
                <div {...getRootProps({className: `profile-picture-upload ${isDragActive ? "selected" : ""}`})}>
                    <input {...getInputProps()}/>
                    <FontAwesomeIcon icon={solid("cloud-arrow-up")}
                                     style={{color: "var(--font-colour)", height: "80px"}}/>

                    <span className="heading"
                          style={{marginTop: "10px", textAlign: "center", marginBottom: "3px"}}>Upload Image</span>
                    <span className="body" style={{textAlign: "center"}}>Drag and drop an image</span>
                    <span className="body" style={{textAlign: "center", fontWeight: "600", marginTop: "8px"}}>OR</span>

                    <button className="primary-button" style={{marginTop: "15px"}} onClick={open}>
                        <FontAwesomeIcon icon={solid("image")} style={{color: "var(--white)", marginRight: "4px"}}/>
                        <span> Select Image</span>
                    </button>

                    <span className="body"
                          style={{textAlign: "center", fontSize: "0.8em", fontWeight: "300", marginTop: "8px"}}>Max dimensions 2048 x 2048, max size 8 MB.</span>
                </div>
            </div>
        </>
    )
}

const EditPage = ({setPage}) => {
    const {handle} = useAuth();
    const profileHandle = useParams().handle;

    useEffect(() => {
        if (handle === "" || profileHandle === "") return;

        if (profileHandle !== handle) {
            setPage(0)
        }
    }, [handle])

    const [followers, setFollowers] = useState([]);
    const [following, setFollowing] = useState([]);

    const [loadingButton, setLoadingButton] = useState(false)

    const [posts, setPosts] = useState([]);

    const [showPicturePrompt, setShowPicturePrompt] = useState(false);
    const [currentBio, setCurrentBio] = useState(null);
    const [currentPicture, setCurrentPicture] = useState("");

    const [loading, setLoading] = useState(true)
    const {setPlainTitle} = useNotifications()

    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

    useEffect(() => {
        GetProfileDetails(profileHandle, (result) => {
            setCurrentPicture(result.picture_url)
            setCurrentBio(result.bio)
            document.getElementById("edit-bio").value = result.bio
            setLoading(false)
        })

        const handleResize = () => {
            setIsMobile(window.innerWidth < 600);
        };
        handleResize()

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    useEffect(() => {
        setPlainTitle("Edit Profile | SketchFlow")

        GetFollowers(profileHandle, (followers) => {
            setFollowers(followers)
        })
        GetFollowing(profileHandle, (following) => {
            setFollowing(following)
        })
        GetPosts(profileHandle, "latest", (posts) => {
            setPosts(posts)
        })
    }, [profileHandle]);

    const HandleFinish = async () => {
        const data = new FormData();
        data.append('bio', currentBio);
        data.append('file', currentPicture);
        setLoadingButton(true)

        const res = await fetch(`https://sketchflow.io/api/user/${profileHandle}/profile`, {
            method: 'PATCH',
            headers: {
                'Authorization': localStorage.getItem('jwt')
            },
            body: data
        })

        await res.json();
        setLoadingButton(false)
        if (res.ok) {

            setPage(0)
        }
    }

    return (
        <div className="profile-page">
            {showPicturePrompt && <ChangePicturePopup onSuccess={(file) => {
                setShowPicturePrompt(false)
                setCurrentPicture(file)
            }} onCancel={() => {
                setShowPicturePrompt(false)
            }}/>}
            <div className="profile-container">
                <div className="profile-header">
                    <img className="profile-edit-picture" onClick={() => setShowPicturePrompt(true)}
                         src={typeof currentPicture === "string" ? currentPicture : URL.createObjectURL(currentPicture)}
                         alt={""}/>
                    <div className="profile-details">
                        <span className="heading profile-handle">{profileHandle}</span>
                        <div className="profile-stats">
                            <span className="profile-statistic">{followers ? followers.length : 0} followers</span>
                            <span className="profile-statistic">{following ? following.length : 0} following</span>
                        </div>
                        {(!isMobile) && <div className={"profile-actions"}>
                            {handle !== profileHandle && <FollowButton profile_handle={profileHandle}/>}
                            {handle === profileHandle &&
                                <button onClick={() => HandleFinish()}
                                        className={`primary-button profile-edit-button ${loadingButton && "disabled"}`}>
                                    {loadingButton ?
                                        <>
                                            <FontAwesomeIcon icon={solid("spinner")} spin style={{color: "white"}}/>
                                            <span> Uploading</span>
                                        </>
                                        :
                                        <>
                                            <FontAwesomeIcon icon={solid("check")} style={{color: "white"}}/>
                                            <span> Finish</span>
                                        </>
                                    }

                                </button>}
                        </div>}
                    </div>
                </div>
                {(isMobile) && <div className={"profile-actions"}>
                    {handle !== profileHandle && <FollowButton profile_handle={profileHandle}/>}
                    {handle === profileHandle &&
                        <button onClick={() => HandleFinish()}
                                className={`primary-button profile-edit-button ${loadingButton && "disabled"}`}>
                            {loadingButton ?
                                <>
                                    <FontAwesomeIcon icon={solid("spinner")} spin style={{color: "white"}}/>
                                    <span> Uploading</span>
                                </>
                                :
                                <>
                                    <FontAwesomeIcon icon={solid("check")} style={{color: "white"}}/>
                                    <span> Finish</span>
                                </>
                            }

                        </button>}
                </div>}
                <div className="profile-body">
                    <span className="heading">About</span>
                    <textarea id={"edit-bio"} className={"profile-edit-bio"}
                              onChange={(e) => setCurrentBio(e.target.value)}
                              placeholder={"A bit about yourself"}></textarea>
                </div>
            </div>
        </div>
    )
}

const ViewPage = ({setPage}) => {
    const {handle, loggedIn} = useAuth();
    const profileHandle = useParams().handle;

    const [followers, setFollowers] = useState([]);
    const [following, setFollowing] = useState([]);



    const [posts, setPosts] = useState([]);

    const [bio, setBio] = useState("");
    const [profilePicture, setProfilePicture] = useState("");

    const [showEnlarged, setShowEnlarged] = useState(false);
    const {setPlainTitle} = useNotifications()
    const [notFound, setNotFound] = useState(false);

    const [filterType, setFilterType] = useState("latest");
    const [loading, setLoading] = useState(true);

    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 600);
        };
        handleResize()

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        GetPosts(profileHandle, filterType, (posts) => {
            setPosts(posts)
        })
    }, [filterType]);
    useEffect(() => {
        setPlainTitle(`${profileHandle} | SketchFlow`)

        GetFollowers(profileHandle, (followers) => {
            setFollowers(followers)
        })
        GetFollowing(profileHandle, (following) => {
            setFollowing(following)
        })
        GetPosts(profileHandle, filterType, (posts) => {
            setPosts(posts)
        })

        GetProfileDetails(profileHandle, (profile) => {
            setLoading(false)
            if (profile === null) {
                setNotFound(true)
                return;
            }
            setBio(profile.bio);
            setProfilePicture(profile.picture_url)
        })
    }, [profileHandle]);

    if (notFound) {
        return (<DefaultPage/>)
    }

    return (
        <>

            <div className={`profile-page ${loading ? "loading" : ""}`}>
                {loading && <FontAwesomeIcon icon={solid("spinner")} spin/>}
                {showEnlarged &&
                    <ImagePopup src={profilePicture === "" ? defaultPicture : profilePicture}
                                onClose={() => setShowEnlarged(false)}/>}
                <div className={`profile-container ${loading ? "loading" : ""}`}>
                    <div className="profile-header">
                        <img className="profile-picture"
                             src={profilePicture === "" ? defaultPicture : profilePicture}
                             onClick={() => setShowEnlarged(true)}
                             alt={profileHandle}/>
                        <div className="profile-details">
                            <span className="heading profile-handle">{profileHandle}</span>
                            <div className="profile-stats">
                                <span className="profile-statistic">{followers ? followers.length : 0} followers</span>
                                <span className="profile-statistic">{following ? following.length : 0} following</span>
                            </div>
                            {(loggedIn && !isMobile) && <div className={"profile-actions"}>
                                {handle !== profileHandle && <FollowButton profile_handle={profileHandle}/>}
                                {handle === profileHandle &&
                                    <button onClick={() => setPage(1)} className={"primary-button profile-edit-button"}>
                                        <FontAwesomeIcon icon={solid("pencil")} style={{color: "white"}}/>
                                        <span> Edit Profile</span>
                                    </button>}
                            </div>}
                        </div>

                    </div>
                    {(loggedIn && isMobile) && <div className={"profile-actions"}>
                        {handle !== profileHandle && <FollowButton profile_handle={profileHandle}/>}
                        {handle === profileHandle &&
                            <button onClick={() => setPage(1)} className={"primary-button profile-edit-button"}>
                                <FontAwesomeIcon icon={solid("pencil")} style={{color: "white"}}/>
                                <span> Edit Profile</span>
                            </button>}
                    </div>}
                    {bio !== "" && <div className="profile-body">
                        <span className="heading">About</span>
                        <br/>
                        <span className={"profile-bio"}>{FormatUserPostText(bio)}</span>
                    </div>}

                    {(posts.length > 0) &&
                        <span className="heading">Posts <span
                            className={"post-stat-detail"}>{posts.length}</span></span>
                    }
                    {(posts.length > 0) &&
                        <div className="profile-posts">
                            <div className={"search-type-container"}>
                                <button className={`search-type ${filterType === "latest" && "selected"}`}
                                        onClick={() => setFilterType("latest")}>Latest
                                </button>
                                <button className={`search-type ${filterType === "oldest" && "selected"}`}
                                        onClick={() => setFilterType("oldest")}>Oldest
                                </button>
                            </div>
                            {posts.map((id) => (<ArtContainer key={id} id={id}/>))}
                        </div>
                    }
                </div>

            </div>
        </>

    )
}

export const ProfilePage = () => {
    const [page, setPage] = useState(0);

    switch (page) {
        case 0:
            return (<ViewPage setPage={setPage}/>)
        case 1:
            return (<EditPage setPage={setPage}/>)
        default:
            return (<span>Error</span>)
    }
}