import {useEffect} from "react";
import {useNotifications} from "../components/navbar";

const Container = () => {
	return (
		<div style={{display: "flex", flexDirection: "column"}}>
			<span className="heading" style={{textAlign: "center"}}>Error 404</span>
			<span className="body" style={{textAlign: "center"}}>Not Found</span>
			<a className={"link"} href={"/"} style={{textAlign: 'center'}}>Return Home</a>
		</div>
	)
}

export const DefaultPage = () => {
	const {setPlainTitle} = useNotifications()
	useEffect(() => {
		setPlainTitle("Not Found | SketchFlow")
	}, []);

	return (
		<div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}>
			<Container/>
		</div>
	)
}