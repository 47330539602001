import '../stylesheet/messages-page.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import React, {useEffect, useState} from "react";
import {useNotifications} from "../components/navbar";
import {useAuth} from "./login-signup-page";
import {GetProfileDetails, GetProfilePicture} from "./profile-page";
import io from 'socket.io-client';
import {Link, useSearchParams} from "react-router-dom";
import CloseButton from "../components/close-button";
import {UserContainer} from "./search-page";
import groupChatIcon from '../media/group-chat-icon.png'
import {FormatTime, FormatTimeDifference, FormatTimeOnly} from "./home-page";
import {Navigate} from "react-router";

const socket = io();

const MessagesList = ({ messages }) => {
    const { handle } = useAuth();

    const TIME_THRESHOLD = 10 * 60 * 1000; // 10 minutes in milliseconds

    const groupedMessages = [];
    let currentGroup = [];

    messages.forEach((msg, index) => {
        if (currentGroup.length === 0) {
            currentGroup.push(msg);
        } else {
            const lastMessage = currentGroup[currentGroup.length - 1];
            const timeDifference = new Date(msg.time) - new Date(lastMessage.time);

            if (msg.author_handle === lastMessage.author_handle && timeDifference <= TIME_THRESHOLD) {
                currentGroup.push(msg);
            } else {
                groupedMessages.push(currentGroup);
                currentGroup = [msg];
            }
        }
    });

    if (currentGroup.length > 0) {
        groupedMessages.push(currentGroup);
    }

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    let previousDate = null;

    return (
        <div className="message-list">
            {groupedMessages.map((group, groupIndex) => {
                const firstMessage = group[0];
                const type = firstMessage.author_handle === handle ? 'user' : 'other';

                const currentDate = new Date(firstMessage.time).toDateString();
                const showDateHeader = previousDate !== currentDate;
                previousDate = currentDate;

                return (
                    <div key={groupIndex} className="inbox-message-group">
                        {showDateHeader && (
                            <div className="message-date-header">
                                {formatDate(firstMessage.time)}
                            </div>
                        )}
                        {type === "other" &&
                            <div className={`inbox-message-group-header ${type}`}>
                                <a href={`/user/${firstMessage.author_handle}`}
                                   className="message-author">{firstMessage.author_handle}</a>
                                <span className="message-timestamp">{FormatTimeOnly(firstMessage.time)}</span>
                            </div>}
                        {group.map((msg, index) => {
                            let positionClass = 'middle';
                            if (group.length === 1) {
                                positionClass = 'solo';
                            } else if (index === 0) {
                                positionClass = 'top';
                            } else if (index === group.length - 1) {
                                positionClass = 'bottom';
                            }

                            return (
                                <div key={msg.id} className={`message ${type} ${positionClass}`}>
                                    {msg.content}
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
};


const NewConversationPopup = ({close, submit}) => {
    const [input, setInput] = useState("");
    const [users, setUsers] = useState([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false)

    function AttemptAddUser(e) {
        e.preventDefault()
        if (loading) return;
        setError("")


        if (users.includes(input)) return;
        if (input.length < 3 || input.length > 20) return;

        setLoading(true)
        GetProfileDetails(input, (details) => {
            setLoading(false)
            if (details === null) {
                setError("Invalid user")
                return;
            }

            setError("")
            document.getElementById("inbox-new-conversation-input").value = ""
            setUsers([...users, details.handle])
        })
    }

    useEffect(() => {
        setError("")
    }, [input])

    return (<>
        <div className={"inbox-new-conversation-blackout"} onClick={close}/>
        <div className={"inbox-new-conversation-container"}>
            <div className={"inbox-new-conversation-top"}>
                <CloseButton onClose={close}/>
                <div className={"heading"} style={{marginLeft: '40px'}}>New Conversation</div>
                <form className={"text-box-form inbox-new-conversation-form"} onSubmit={AttemptAddUser}>
                        <input id={"inbox-new-conversation-input"} maxLength={20} type={"text"}
                               onChange={(e) => setInput(e.target.value)}
                               placeholder={"Add User..."}
                               className={"text-box-input"}
                               autoComplete={"off"}/>

                        <button type={"submit"}
                                className={`text-box-submit ` + (input.length < 3 || loading ? "disabled" : "")}>
                            {loading ?
                                <FontAwesomeIcon icon={solid("spinner")} spin style={{height: '16px', color: "inherit"}}/>
                            :
                                <>
                                    <FontAwesomeIcon icon={solid("plus")} style={{height: '16px', color: "inherit"}}/>
                                    <span style={{marginLeft: '5px'}}> Add</span>
                                </>
                               }
                        </button>

                </form>
                {error !== "" &&
                    <div className={"field-error-message"}>
                        <FontAwesomeIcon icon={solid("warning")} style={{color: "inherit"}}/>
                        <span> {error}</span>
                    </div>}

                <div className={"inbox-new-conversation-list"}>
                    {users.map((u, index) => {
                        return <>
                            <UserContainer userHandle={u} key={index}/>
                        </>
                    })}
                </div>
            </div>

            <button type={"submit"} onClick={() => submit(users)}
                    className={`primary-button ` + (users.length === 0 && "disabled")}>
                <FontAwesomeIcon icon={solid("plus")} style={{height: '16px', color: "inherit"}}/>
                <span> Create</span>
            </button>
        </div>
    </>)
}

export const InboxPage = () => {
    const {handle, loggedIn} = useAuth()
    const [input, setInput] = useState("")
    const {setPlainTitle} = useNotifications()
    const [messages, setMessages] = useState([])
    const [conversations, setConversations] = useState([])
    const [currentConvo, setCurrentConvo] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const [showNewConversationPopup, setShowNewConversationPopup] = useState(false)

    const [mobileShowList, setMobileShowList] = useState(true)
    const [isMobile, setIsMobile] = useState(false)

    const [isLoadingMessages, setIsLoadingMessages] = useState(false)
    const [isLoadingConversations, setIsLoadingConversations] = useState(false)

    async function GetConversations(callback) {
        const resp = await fetch(`https://sketchflow.io/api/conversations`, {
            method: 'GET',
            headers: {
                'Authorization': localStorage.getItem('jwt')
            }
        });



        const result = await resp.json();
        if (resp.ok) {
            let convos = result.result
            for (let i = 0; i < convos.length; i++) {
                let convo = convos[i]
                convo.participants = JSON.parse(convo.participants)
                convo.name = convo.participants
                    .filter(p => p.handle !== handle)
                    .map(p => p.handle)
                    .join(', ');


                convo.latest_message = JSON.parse(convo.latest_message)
            }
            callback(convos)
            return;
        }
        console.error("Error fetching");
        callback([])
    }

    async function OpenedConversation(id) {
        const resp = await fetch(`https://sketchflow.io/api/conversations/${id}/open`, {
            method: 'POST',
            headers: {
                'Authorization': localStorage.getItem('jwt'),
                'Content-Type': 'application/json',
            }
        });

        await resp.json();
    }

    async function GetMessages(callback) {
        const resp = await fetch(`https://sketchflow.io/api/conversations/${currentConvo.id}/messages`, {
            method: 'POST',
            headers: {
                'Authorization': localStorage.getItem('jwt'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                current: messages
            })
        });

        const result = await resp.json();
        if (resp.ok) {
            callback(result.messages)
            return;
        }
        console.error("Error fetching");
        callback([])
    }

    async function SendMessage(e) {
        e.preventDefault()
        if (input === "") return;

        OpenedConversation(currentConvo.id)
        socket.emit('sendMessage', {conversationId: currentConvo.id, author: handle, content: input});

        const elementInput = document.getElementById("inbox-message-input")
        elementInput.value = ""
        setInput("")
    }

    function UpdateConversations() {
        setIsLoadingConversations(true)
        GetConversations((convos) => {
            const updatedConvos = [...convos]; // Clone the conversations array
            const profilePicturePromises = convos.map((convo, index) => {
                if (convo.participants.length > 2) {
                    updatedConvos[index].icon_url = groupChatIcon;
                    return;
                }
                const otherParticipant = convo.participants.filter(p => p.handle !== handle)[0];
                return new Promise((resolve) => {
                    GetProfilePicture(otherParticipant.handle, (pfp) => {
                        updatedConvos[index].icon_url = pfp;
                        resolve();
                    });
                });
            });

            // Wait for all profile picture requests to complete
            Promise.all(profilePicturePromises).then(() => {
                setIsLoadingConversations(false)
                setConversations(updatedConvos);

                if (searchParams.has("conversation")) {
                    const requestedConvo = updatedConvos.filter(c => c.name === searchParams.get("conversation"))
                    if (requestedConvo.length === 0) {
                        FetchNewConversation([searchParams.get("conversation")], () => {
                            UpdateConversations()
                        })
                        return;
                    }
                    searchParams.delete("conversation")
                    setCurrentConvo(requestedConvo[0])
                }
            });
        });
    }

    async function FetchNewConversation(users, callback) {
        if (users.length === 0) return;
        if (users.length === 1) {
            for (const convo of conversations) {
                if (convo.participants.length === 1 && convo.participants.some(p => users.includes(p.handle))) {
                    // check to see if any 1-on-1 groups exist with other user
                    return;
                }
            }
        }

        const resp = await fetch(`https://sketchflow.io/api/conversations`, {
            method: 'PUT',
            headers: {
                'Authorization': localStorage.getItem('jwt'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                users: users
            })
        });

        const resData = await resp.json();

        if (resp.ok) {
            callback(true)
            return;
        }
        callback(false)
    }

    function SubmitNewConversation(users) {
        FetchNewConversation(users, (success) => {
            if (!success) return;
            UpdateConversations()
            setShowNewConversationPopup(false)
        })
    }

    function LoadMoreMessages(callback) {
        setIsLoadingMessages(true)
        GetMessages((msgs) => {
            setIsLoadingMessages(false)
            if (messages.length === msgs.length) return;
            setMessages(msgs)
            console.log(msgs)
            if (callback) callback()
        })
    }

    function HandleScroll(e) {
        e.preventDefault()
        if (e.target.scrollTop === 0 && e.target.scrollHeight > e.target.clientHeight) {
            LoadMoreMessages(() => {
                const distanceFromBottom = (e.target.scrollHeight - e.target.clientHeight) - e.target.scrollTop;
                setTimeout(() => {
                    e.target.scrollTop = e.target.scrollHeight - e.target.clientHeight - distanceFromBottom;
                }, 0)
            })
        }
    }

    useEffect(() => {
        if (handle === "") return;
        setPlainTitle("Messages | SketchFlow")

        UpdateConversations()

    }, [handle]);

    useEffect(() => {
        function checkIsMobile() {
            setIsMobile(window.innerWidth <= 600);
        }

        window.addEventListener("resize", checkIsMobile)
        checkIsMobile()

        return () => {
            window.removeEventListener("resize", checkIsMobile);
        }
    }, []);

    useEffect(() => {
        setConversations((convos) => {
            if (!messages || messages.length === 0 || !currentConvo || !currentConvo.id) return convos;

            const latestMessage = messages[messages.length - 1];

            return convos.map((convo) => {
                if (convo.id === currentConvo.id) {
                    return {
                        ...convo,
                        latest_message: {
                            content: latestMessage.content,
                            author_handle: latestMessage.author_handle,
                            time: latestMessage.time
                        }
                    };
                }
                return convo;
            });
        });
    }, [messages]);

    useEffect(() => {
        if (!currentConvo) {
            setMessages([])
            return
        }

        const elementMessages = document.getElementById("inbox-messages")
        setMessages([])

        document.getElementById("inbox-message-input").focus()

        socket.on('newMessage', (message) => {
            if (!currentConvo) return;
            if (message.conversationId !== currentConvo.id) {
                setConversations((convos) => {
                    return convos.map((convo) => {
                        if (convo.id === message.conversationId) {
                            return {
                                ...convo,
                                latest_message: {
                                    content: message.content,
                                    author_handle: message.author_handle,
                                    time: message.time
                                }
                            };
                        }
                        return convo;
                    });
                });
                return;
            }
            setMessages((prevMessages) => [...prevMessages, message]);
            setTimeout(() => {
                elementMessages.scrollTop = elementMessages.scrollHeight;
            }, 100)
        });

        LoadMoreMessages(() => {
            setTimeout(() => {
                elementMessages.scrollTop = elementMessages.scrollHeight;
            }, 100)
        });

        return () => {
            socket.off('newMessage');
        }
    }, [currentConvo])

    useEffect(() => {
        const convoId = []
        for (const convo of conversations) {
            socket.emit('joinConversation', convo.id);
            convoId.push(convo.id)
        }

        return(() => {
            for (const convo of convoId) {
                socket.emit('leaveConversation', convo);
            }
        })
    }, [conversations])

    function IsUnread(convo) {
        return convo.participants.some(p => p.handle === handle && p.last_seen < convo.latest_message.time)
    }


    if (!loggedIn) {
        return (<Navigate to={"/login"}/>)
    }



    return (<div className={"inbox-page"}>
        {showNewConversationPopup &&
            <NewConversationPopup close={() => setShowNewConversationPopup(false)} submit={SubmitNewConversation}/>}
        <div className={`inbox-list ${!mobileShowList ? "mobile-hidden" : ""}`}>
            <div className={"heading"} style={{marginBottom: "16px"}}>
                <span style={{marginLeft: '36px', marginRight: '3px', fontSize: '0.9em'}}>Messages</span>
                <button className={"nav-icon-button"} onClick={() => setShowNewConversationPopup(true)}>
                    <FontAwesomeIcon icon={(solid("pen-to-square"))}
                                     style={{color: "var(--font-colour)", height: '20px'}}/>
                </button>
            </div>
            {isLoadingConversations && <div className={"spinner-container"}>
                <FontAwesomeIcon icon={solid("spinner")} spin/>
            </div>}
            {conversations.sort((a, b) => {
                const aTime = a.latest_message && a.latest_message.time ? new Date(a.latest_message.time) : 0;
                const bTime = b.latest_message && b.latest_message.time ? new Date(b.latest_message.time) : 0;
                return bTime - aTime;
            }).map((convo, index) => {
                return <div key={index} onClick={() => {
                    setMessages([])
                    setMobileShowList(false)
                    setCurrentConvo(convo)
                    OpenedConversation(convo.id)

                    convo.participants.filter(p => p.handle === handle)[0].last_seen = Date.now()
                }}
                            className={`inbox-conversation ${currentConvo && currentConvo.id === convo.id && "selected"}`}>
                    <div className={"user-card-left"}>
                        <img className={"user-card-picture"} src={convo ? convo.icon_url || "" : ""}/>
                    </div>
                    <div className={"user-card-right"}>

                            <span className={"user-card-handle inbox-user-handle"}>{convo.name}</span>

                        {convo.latest_message.content !== null &&
                            <span
                                className={`user-card-message ${IsUnread(convo) ? "unread" : ""}`}>
                                {`${convo.latest_message.author_handle === handle ? "You: " : ""}${convo.latest_message.content}`}
                                <span
                                    className={"inbox-message-timestamp"}>{FormatTimeDifference(Date.now(), convo.latest_message.time)}</span>
                            </span>}
                    </div>
                    <div className={"user-card-unread-container"}>
                        {IsUnread(convo) &&
                            <div className={"inbox-message-unread-badge"}/>}
                    </div>
                </div>
            })}

        </div>
        <div className={"inbox-content"}>
            {currentConvo && <div className={"inbox-header"}>
                {isMobile && <button className={"nav-icon-button"} onClick={() => setMobileShowList(true)}>
                    <FontAwesomeIcon icon={solid("chevron-left")}
                                     style={{height: '20px', color: 'var(--font-colour)'}}/>
                </button>}
                {currentConvo.participants.length <= 2 ?
                    <Link to={`/user/${currentConvo.name}`}>
                        <img src={currentConvo ? currentConvo.icon_url || "" : ""} className={"message-picture"}/>
                    </Link>
                    :
                    <img src={currentConvo ? currentConvo.icon_url || "" : ""} className={"message-picture"}/>
                }

                <span className={"subheading"}>{currentConvo.name}</span>
            </div>}
            <div className={"inbox-messages"} id={"inbox-messages"} onScroll={HandleScroll}>
                {isLoadingMessages && <div className={"spinner-container"}>
                    <FontAwesomeIcon icon={solid("spinner")} spin/>
                </div> }
                <MessagesList messages={messages}/>
            </div>
            {currentConvo && <form className={"text-box-form inbox-message-form"} onSubmit={SendMessage}>
                    <input id={"inbox-message-input"} maxLength={200} type={"text"}
                           onChange={(e) => setInput(e.target.value)}
                           placeholder={"Message..."}
                           autoComplete={"off"}
                           className={"text-box-input inbox-message-input"}/>

                <button type={"submit"}
                        className={`text-box-submit inbox-message-submit ` + (input === "" && "disabled")}>
                    <FontAwesomeIcon icon={solid("paper-plane")} style={{height: '16px', color: "inherit"}}/>
                    <span style={{marginLeft: '5px'}}> Send</span>
                </button>


            </form>}
        </div>
    </div>)
}