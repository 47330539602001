import React, {createContext, useContext, useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes, useLocation} from 'react-router-dom';

import './stylesheet/global.css';
import {AuthProvider, Login, Signup, useAuth} from "./pages/login-signup-page";
import {NavBar, NotificationsProvider} from "./components/navbar";
import {ProfilePage} from "./pages/profile-page";
import {createRoot} from "react-dom/client";
import {CreatePage} from './pages/create-page';
import {DefaultPage} from "./pages/default-page";
import {HomePage} from "./pages/home-page";
import {Navigate} from "react-router";
import {SettingsPage, SettingsProvider, useSettings} from "./pages/settings-page";
import Push from 'push.js';
import {PostAnalyticsPage, PostEditPage, PostPage} from "./pages/post-pages";
import {SearchPage} from "./pages/search-page";
import {InboxPage} from "./pages/messages-page";
import {PrivacyPage} from "./pages/privacy-page";

export const RouteHandler = () => {
    return (
        <BrowserRouter>
            <AuthProvider>
                <SettingsProvider>
                    <NotificationsProvider>
                        <App/>
                    </NotificationsProvider>
                </SettingsProvider>
            </AuthProvider>
        </BrowserRouter>
    )
}

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

const App = () => {
    const location = useLocation();
    const {loggedIn} = useAuth();
    const {theme} = useSettings();

    useEffect(() => {
        const backgroundColor = `var(--background-colour-${theme})`;
        const transparentBackgroundColor = `var(--background-colour-transparent-${theme})`;
        const outlineColour = `var(--outline-colour-${theme})`;
        const inputColour = `var(--input-background-colour-${theme})`;
        const fontColor = `var(--font-colour-${theme})`;
        const secondaryFontColor = `var(--secondary-font-colour-${theme})`;
        const hoverColor = `var(--hover-color-${theme})`;
        const otherMessageColor = `var(--other-message-color-${theme})`;

        document.documentElement.style.setProperty('--background-colour', backgroundColor);
        document.documentElement.style.setProperty('--background-colour-transparent', transparentBackgroundColor);
        document.documentElement.style.setProperty('--input-background-colour', inputColour);
        document.documentElement.style.setProperty('--outline-colour', outlineColour);
        document.documentElement.style.setProperty('--hover-color', hoverColor);
        document.documentElement.style.setProperty('--font-colour', fontColor);
        document.documentElement.style.setProperty('--secondary-font-colour', secondaryFontColor);
        document.documentElement.style.setProperty('--other-message-color', otherMessageColor);
    }, [theme])

    useEffect(() => {
        if (!loggedIn) return;
        Push.Permission.request(() => {
            console.log('Notification permissions granted.');
        }, () => {
            console.log('Notification permissions denied.');
        });
        if ('serviceWorker' in navigator) {
            const handleServiceWorker = async () => {
                const register = await navigator.serviceWorker.register("/sw.js");

                let subscription = await register.pushManager.getSubscription();
                if (subscription !== null) {
                    try {
                        await subscription.unsubscribe();
                    } catch (error) {
                        console.error('Failed to unsubscribe from previous subscription:', error);
                    }
                }

                subscription = await register.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: "BBjLCwOTIniEwn5EslqejA_NP76TJkkExn1oZvbpfRKCltHwqrDGWQz5u1B6hW2PuvWtkREMqQDDsOO6EMbstH8",
                });

                const res = await fetch("https://sketchflow.io/api/save-subscription", {
                    method: "POST",
                    body: JSON.stringify(subscription),
                    headers: {
                        "Authorization": localStorage.getItem('jwt'),
                        "content-type": "application/json",
                    },
                });

                const data = await res.json();
            };
            try {
                handleServiceWorker();
            } catch (ignored) {

            }
        }
    }, [])


    const isSignUpOrLogin = location.pathname === '/signup' || location.pathname === '/login';


    return (
        <>
            {!isSignUpOrLogin && (
                <NavBar/>
            )}
            <div id={"page"} style={{height: '100%'}}>
                <Routes>
                    <Route path="/" Component={HomePage}/>
                    <Route path="/create" Component={CreatePage}/>
                    <Route path="/login" element={loggedIn ? <Navigate to={"/"}/> : <Login/>}/>
                    <Route path="/signup" element={loggedIn ? <Navigate to={"/"}/> : <Signup/>}/>
                    <Route path="/user/:handle" Component={ProfilePage}/>
                    <Route path="/@:handle" Component={ProfilePage}/>
                    <Route path="/post/:id" Component={PostPage}/>
                    <Route path="/post/:id/edit" Component={PostEditPage}/>
                    <Route path="/post/:id/analytics" Component={PostAnalyticsPage}/>
                    <Route path="/settings" Component={SettingsPage}/>
                    <Route path="/search" Component={SearchPage}/>
                    <Route path="/inbox" Component={InboxPage}/>
                    <Route path="/privacy" Component={PrivacyPage}/>
                    <Route path="*" Component={DefaultPage}/>
                </Routes>
            </div>
        </>
    );
}

document.addEventListener('DOMContentLoaded', function () {
    createRoot(document.getElementById('root')).render(<RouteHandler/>)
})
