import React, {useEffect, useRef, useState} from "react";
import './../stylesheet/dropdown.css'

export const DropDownItem = ({onClick, children, close}) => {
    return (<button onClick={() => {
        onClick();
        close();
    }} className={"dropdown-item"}>
        <span>{children}</span>
    </button>)
}

export const DropDown = ({priority, style, showing, children, close}) => {
    const containerRef = React.useRef();

    React.useEffect(() => {
        if (showing && containerRef.current) {
            const rect = containerRef.current.getBoundingClientRect();
            const viewportWidth = window.innerWidth;
            if (rect.left < 0) {
                containerRef.current.style.left = `-34px`;
            } else if (rect.right > viewportWidth) {
                containerRef.current.style.right = `${rect.right - viewportWidth}px`;
            }
        }
    }, [showing]);

    if (showing) {

        const childrenWithCloseProp = React.Children.map(children, child => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child, {close});
            }
            return child;
        });

        const containerIndex = 10 + (priority || 0);
        const detectorIndex = 9 + (priority || 0);

        return (<>
            <div onClick={close} style={{zIndex: detectorIndex}} className={"dropdown-close-detector"}/>
            <div ref={containerRef} style={{zIndex: containerIndex}} style={style} className={"dropdown-container"} >
                {childrenWithCloseProp}
            </div>
        </>)
    }
}
