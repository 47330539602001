import '../stylesheet/login-signup-page.css';
import {Link} from "react-router-dom";
import {Navigate, useNavigate} from "react-router";
import React, {createContext, useCallback, useContext, useEffect, useState} from "react";
import backgroundDark from '../media/landing-page-background-dark.svg'
import backgroundLight from '../media/landing-page-background-light.svg'
import logoLight from '../media/logo-banner-light.svg'
import logoDark from '../media/logo-banner-dark.svg'
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FormActionButton, FormError, FormFieldSection} from "../components/form-essentials";
import bannerlogo from "../media/logo-banner-light.svg";
import bannerlogodark from "../media/logo-banner-dark.svg";
import {useSettings} from "./settings-page";
import {useNotifications} from "../components/navbar";

const usernamePattern = /^[a-z0-9_]{3,20}$/;
const passwordPattern = /^[\S+]{4,40}$/;

const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [handle, setHandle] = useState("");

    const jwt = localStorage.getItem('jwt');
    if (jwt && loggedIn === false) {
        setLoggedIn(true); // likely is logged in, remaining code checks for invalid/expired
    }

    const checkAuth = useCallback(async () => {
        if (jwt) {
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
            return;
        }
        try {
            const resp = await fetch(`https://sketchflow.io/api/auth/session/validate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    jwt: jwt
                })
            });

            const data = await resp.json();
            if (resp.ok) {
                setLoggedIn(data.result);
                setHandle(data.handle);
            } else {
                localStorage.removeItem('jwt');
                setLoggedIn(false);
                setHandle("");
            }
        } catch (error) {
            console.error("Error validating: " + error);
            localStorage.removeItem('jwt');
            setLoggedIn(false);
            setHandle("");
        }
    }, [jwt])

    // Call authenticated function when component mounts
    useEffect(() => {
        checkAuth();
    }, [checkAuth]);

    return (
        <AuthContext.Provider value={{loggedIn, setLoggedIn, checkAuth, handle}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};


const AuthField = ({update, id, type}) => {
    return (
        <input className="login-field" id={id} type={type} onChange={e => update(e.target.value)}/>
    )
}

export const Login = () => {
    const {checkAuth, setLoggedIn} = useAuth();
    const {setPlainTitle} = useNotifications()
    const {theme} = useSettings();

    useEffect(() => {
        setPlainTitle("Log In | SketchFlow")
    }, []);

    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");
    const [serverError, setServerError] = useState("");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    let disabled = !password || !username;


    async function LoginUser() {
        const username = document.getElementById("username").value;
        if (username.length === 0) return;
        const password = document.getElementById("password").value;
        if (password.length === 0) return;

        try {
            const resp = await fetch(`https://sketchflow.io/api/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    handle: username,
                    password: password
                })
            });

            if (resp.ok) {
                const result = await resp.json();
                localStorage.setItem('jwt', result.jwt);
                setLoggedIn(true);
                checkAuth();
                navigate("/")
                return;
            }
            console.error("Error fetching");
        } catch (error) {
            console.error("Error fetching: " + error)
        }
    }

    async function HandleSubmit(e) {
        e.preventDefault()
        setLoading(true)

        try {
            const res = await fetch("https://sketchflow.io/api/auth/login/validate", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({username, password}) // Send username and password to server
            })

            if (!res.ok) {
                setServerError("A server error occurred, please try again later.")
                setLoading(false)
                return;
            }

            const data = await res.json();

            if (data.error) {
                setServerError(data.message);
                setLoading(false)
            } else {
                setServerError(" ")
                await LoginUser();
                setLoading(false)
            }
        } catch (error) {
            console.error(error);
            setServerError("A server error occurred, please try again later.")
            setLoading(false)
        }
    }

    return (
        <div className={"auth-container"}>
            <img src={(theme === "light" ? backgroundLight : backgroundDark)} className={"auth-background"} alt=""/>
            <Link to={"/"}>
                <img className="auth-logo" src={(theme === "light" ? logoLight : logoDark)} alt="logo"
                     draggable="false"/>
            </Link>

            <form className="auth-box" onSubmit={HandleSubmit}>
                <h2 className="title">Log In</h2>
                <h5 className="field-heading">Username</h5>
                <AuthField id="username" type="text" update={setUsername} autoComplete={"username"}/>
                <h5 className="field-heading">Password</h5>
                <AuthField id="password" type="password" update={setPassword} autoComplete={"current-password"}/>
                <FormActionButton disabled={disabled || loading}>
                    {!loading ?
                        <FontAwesomeIcon icon={solid("user")}/>
                        :
                        <FontAwesomeIcon icon={solid("spinner")} spin/>
                    }
                    {!loading && <span> Log In</span>}
                </FormActionButton>
                <FormError body={serverError}/>
                <span style={{marginTop: "10px"}}>Don't have an account? <Link className="link"
                                                                               to="/signup">Sign Up</Link></span>
                <span style={{marginTop: "10px"}}>Or <Link className="link"
                                                           to="/">continue as guest</Link></span>

            </form>
        </div>
    );
}

export const Signup = () => {
    const {checkAuth, setLoggedIn} = useAuth();
    const {theme} = useSettings()
    const {setPlainTitle} = useNotifications()

    useEffect(() => {
        setPlainTitle("Sign Up | SketchFlow")
    }, []);

    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [serverError, setServerError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    let disabled = false;
    const errors = {username: "", password: "", repeatPassword: ""};
    if (submitted) {
        if (!password) {
            disabled = true;
            errors.password = "This is a required field.";
        }
        if (!username) {
            disabled = true;
            errors.username = "This is a required field.";
        }
        if (!repeatPassword) {
            disabled = true;
            errors.repeatPassword = "This is a required field.";
        }
    } else {
        if (!password) disabled = true;
        if (!username) disabled = true;
        if (!repeatPassword) disabled = true;
    }
    if (!errors.username && username.toLowerCase() !== username) {
        disabled = true;
        errors.username = "Username must be lowercase.";
    }
    if (!errors.username && !usernamePattern.test(username) && username !== "") {
        disabled = true;
        errors.username = "Username must be between 3 - 20 characters.";
    }
    if (!errors.repeatPassword && repeatPassword !== password && repeatPassword !== "") {
        disabled = true;
        errors.repeatPassword = "Passwords must match!";
    }
    if (!errors.password && !passwordPattern.test(password) && password !== "") {
        disabled = true;
        errors.password = "Password must be 4 - 40 characters long.";
    }

    async function Register() {
        if (username.length === 0) return;
        if (password.length === 0) return;
        if (repeatPassword.length === 0) return;
        if (password !== repeatPassword) return;

        try {
            const resp = await fetch(`https://sketchflow.io/api/auth/signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    handle: username,
                    password: password
                })
            });

            if (resp.ok) {
                const result = await resp.json();
                localStorage.setItem('jwt', result.jwt);
                setLoggedIn(true);
                checkAuth();
                navigate("/")
                return;
            }
            console.error("Error fetching");
        } catch (error) {
            console.error("Error fetching: " + error)
        }
    }

    async function HandleSubmit(e) {
        e.preventDefault()
        setSubmitted(true);
        setLoading(true)

        try {
            const res = await fetch("https://sketchflow.io/api/auth/signup/validate", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({username, password}) // Send username and password to server
            })

            if (!res.ok) {
                setServerError("A server error occurred, please try again later.")
                setLoading(false)
                return;
            }

            const data = await res.json();

            if (data.error) {
                setServerError(data.message);
                setLoading(false)
            } else {
                setServerError(" ")
                await Register();
                setLoading(false)
            }
        } catch (error) {
            setServerError("A server error occurred, please try again later.")
            setLoading(false)
        }

    }


    return (
        <div className={"auth-container"}>
            <img className={"auth-background"} src={(theme === "light" ? backgroundLight : backgroundDark)} alt=""/>
            <Link to={"/"}>
                <img className="auth-logo" src={(theme === "light" ? logoLight : logoDark)} alt="logo"
                     draggable="false"/>
            </Link>
            <form className="auth-box" onSubmit={HandleSubmit}>
                <h2 className="title">Sign Up</h2>
                <FormFieldSection heading="Username" onChange={setUsername} type="text" id="username"
                                  error={errors.username} autoComplete={"username"}/>
                <FormFieldSection heading="Password" onChange={setPassword} type="password" id="password"
                                  error={errors.password} autoComplete={"new-password"}/>
                <FormFieldSection heading="Repeat Password" onChange={setRepeatPassword} type="password"
                                  id="password-repeat" error={errors.repeatPassword} autoComplete={"new-password"}/>
                <FormActionButton disabled={disabled || loading}>
                    {!loading ?
                        <FontAwesomeIcon icon={solid("user-plus")}/>
                        :
                        <FontAwesomeIcon icon={solid("spinner")} spin/>}
                    {!loading && <span> Sign Up</span>}
                </FormActionButton>
                <FormError body={serverError}/>
                <span style={{marginTop: "10px"}}>Already have an account? <Link className="link"
                                                                                 to="/login">Log In</Link></span>
                <span style={{marginTop: "10px"}}>Or <Link className="link"
                                                           to="/">continue as guest</Link></span>
            </form>
        </div>
    );
}
