import React from "react";

export const FormError = ({body}) => {
    return (
        <p className="field-error-message">{body !== "" ? body : " "}</p>
    )
}


export const FormFieldSection = ({heading, onChange, id, type, error, autoComplete = "", paragraph = false, required = false}) => {
    return (
        <div style={{width: "100%"}}>
            <h5 className={`field-heading${required ? " required" : ""}`}>{heading}</h5>
            {paragraph ?
                <textarea className={`login-field${error ? " field-error" : ""}`} id={id} style={{resize: "none", height: "100px"}}
                          onChange={e => onChange(e.target.value)}
                rows={20}/> :
                <input autoComplete={autoComplete} className={`login-field${error ? " field-error" : ""}`} id={id} type={type}
                       onChange={e => onChange(e.target.value)}/>}
            <FormError body={error ?? " "}/>
        </div>
    )
}

export const FormActionButton = ({onClick, disabled, children}) => {
    const style = {width: "100%", margin: "40px 0 0 0", height: "50px"};
    return (
        disabled ?
            <button className="primary-button disabled" style={style}>{children}</button>
            :
            <button type={"submit"} className="primary-button" onClick={() => {
                if (onClick) onClick()
            }} style={style}>{children}</button>
    )
}