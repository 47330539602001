import React, {createContext, useContext, useEffect, useState} from "react";
import bannerLogoLight from "../media/logo-banner-light.svg";
import bannerLogoDark from "../media/logo-banner-dark.svg";
import iconLogo from "../media/logo-icon.svg";
import "./../stylesheet/navbar.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {regular, solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Navigate, useNavigate} from "react-router";
import {useAuth} from "../pages/login-signup-page";
import {DropDown, DropDownItem} from "./dropdown";
import {useSettings} from "../pages/settings-page";
import {Link, useLocation} from "react-router-dom";
import {FormatTime, FormatTimeDifference} from "../pages/home-page";
import {GetProfilePicture} from "../pages/profile-page";
import defaultPicture from "../media/default-picture.jpg"

const NotificationsContext = createContext();

export const NotificationsProvider = ({children}) => {
    const [notifications, setNotifications] = useState([])
    const [notificationCount, setNotificationCount] = useState(0);
    const [notificationPage, setNotificationPage] = useState(1);
    const [plainTitle, setPlainTitle] = useState("");

    useEffect(() => {
        if (notificationCount === 0) {
            document.title = plainTitle
            return
        }
        document.title = `(${notificationCount}) ${plainTitle}`
    }, [notificationCount, plainTitle])

    return (
        <NotificationsContext.Provider value={{
            notifications,
            setNotifications,
            plainTitle,
            setPlainTitle,
            notificationPage,
            setNotificationPage,
            notificationCount,
            setNotificationCount
        }}>
            {children}
        </NotificationsContext.Provider>
    );
};

export const useNotifications = () => {
    return useContext(NotificationsContext);
};

async function FetchNotifications(page, callback) {
    const resp = await fetch(`https://sketchflow.io/api/notification-page/${page}`, {
        method: 'GET',
        headers: {
            'Authorization': localStorage.getItem('jwt'),
            'Content-Type': 'application/json',
        }
    });

    const result = await resp.json();
    if (resp.ok) {
        callback(result.result)
        return;
    }
    console.error("Error fetching");
}

async function DismissNotification(id, callback) {
    const resp = await fetch(`https://sketchflow.io/api/notification/${id}`, {
        method: 'DELETE',
        headers: {
            'Authorization': localStorage.getItem('jwt')
        }
    });

    await resp.json();
    if (resp.ok) {
        callback(null)
        return;
    }
    console.error("Error fetching");
    callback("error")
}

async function DismissAllNotification(callback) {
    const resp = await fetch(`https://sketchflow.io/api/notification/all`, {
        method: 'DELETE',
        headers: {
            'Authorization': localStorage.getItem('jwt')
        }
    });

    await resp.json();
    if (resp.ok) {
        callback(null)
        return;
    }
    console.error("Error fetching");
    callback("error")
}

const Notification = ({key, dismiss, subject, page, time, children, close}) => {
    const timeString = FormatTimeDifference(time, Date.now());
    return (<div key={key} className={"notification-item"}>

        <div className={"notification-top"}>
            <span className={"notification-heading"}>{subject}</span>

            <span className="notification-timestamp" title={FormatTime(time)}>{timeString}</span>
            <button onClick={dismiss} className={"post-edit-button"}>
                <FontAwesomeIcon icon={solid("trash")}
                                 style={{color: "var(--font-colour)"}}/>
            </button>
        </div>
        <Link to={page}>
            <p className={"notification-body"} onClick={close}>{children}</p>
        </Link>
    </div>)
}

export const NavBar = () => {
    const {setLoggedIn, loggedIn, handle} = useAuth();
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
    const [isSmallLogo, setIsSmallLogo] = useState(window.innerWidth < 1000);
    const [showMobileSearchBar, setShowMobileSearchBar] = useState(false);
    const {theme} = useSettings()
    const location = useLocation()

    const [searchInput, setSearchInput] = useState("");
    const [showProfileDropDown, setShowProfileDropDown] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);
    const {
        notifications,
        setNotifications,
        notificationPage,
        setNotificationPage,
        notificationCount,
        setNotificationCount,
    } = useNotifications();

    const [profilePicture, setProfilePicture] = useState("");

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 600);
            setIsSmallLogo(window.innerWidth < 1000);
        };
        handleResize()

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (!loggedIn) return;
        FetchNotifications(notificationPage, (result) => {
            setNotifications(result.notifications);
            setNotificationCount(result.total)
        })

        if (location.pathname === "/search") {
            const queryParams = new URLSearchParams(location.search);
            const query = queryParams.get('q');
            setSearchInput(query)
            if (document.getElementById("search-bar"))
                document.getElementById("search-bar").value = query
        }
    }, [])

    function LoadNewBatch() {
        FetchNotifications(notificationPage + 1, (result) => {
            setNotifications([...notifications, ...result.notifications]);
            setNotificationCount(result.total)
        })
        setNotificationPage(notificationPage + 1)
    }

    function DismissAll() {
        DismissAllNotification((result) => {
            setNotifications([]);
            setNotificationCount(0)
        })
        setNotificationPage(1)
    }

    useEffect(() => {
        if (handle === "") return;
        GetProfilePicture(handle, (pfp) => {
            setProfilePicture(pfp)
        })
    }, [handle])

    const handleSubmit = (event) => {
        event.preventDefault();
        if (searchInput === "") return;

        const encodedSearchInput = encodeURIComponent(searchInput);
        navigate(`/search?q=${encodedSearchInput}`, {replace: true})

        setShowMobileSearchBar(false)
    };

    function Logout() {
        localStorage.setItem('jwt', null);
        setLoggedIn(false);
        window.location.reload()


        return (<Navigate to={"/"}/>)
    }

    const logo = isSmallLogo ? iconLogo : (theme === "light" ? bannerLogoLight : bannerLogoDark);
    return (
        <nav className={"nav"}>
            <div className="left-side-container">
                <Link to={"/"}>
                    <span className="nav-logo">
                        <img src={logo} alt={""}/>
                    </span>
                </Link>
            </div>
            {!isMobile &&
                <form onSubmit={handleSubmit} className="search-bar-form text-box-form">
                        <input id={"text-box-input"} maxLength={100} autoComplete={"off"} type={"text"}
                               onChange={(e) => setSearchInput(e.target.value)} placeholder="Search..."
                               className={"search-bar-input"}/>

                        <button type={"submit"}
                                className={`text-box-submit ` + (searchInput === "" ? "disabled" : "")}>
                            <FontAwesomeIcon icon={solid("magnifying-glass")} style={{height: '16px', color: "inherit"}}/>
                        </button>
                </form>}
            <div className="right-side-container">
                {isMobile &&
                    <button className={"nav-icon-button"} onClick={() => setShowMobileSearchBar(!showMobileSearchBar)}>
                        <FontAwesomeIcon icon={(solid("magnifying-glass"))}
                                         style={{color: "var(--font-colour)", height: '20px'}}/>
                    </button>}
                {loggedIn ? <><Link to={"/create"} title={"Create"}>
                        <button className={"nav-icon-button"}>
                            <FontAwesomeIcon icon={(solid("plus"))}
                                             style={{color: "var(--font-colour)", height: '22px'}}/>
                        </button>
                    </Link>
                        <Link to={"/inbox"} title={"Messages"}>
                            <button className={"nav-icon-button"}>
                                <FontAwesomeIcon icon={(regular("comment-dots"))}
                                                 style={{
                                                     color: "var(--font-colour)",
                                                     transform: 'scaleX(-1)',
                                                     height: '22px'
                                                 }}/>
                            </button>
                        </Link>
                        <div className={"notifications-container"}>
                            <DropDown showing={showNotifications} style={{display: 'flex', alignItems: 'center'}} close={() => setShowNotifications(false)}>
                                {notificationCount > 0 ?
                                    <>
                                        <button className={"primary-button notification-load-button"}
                                                onClick={DismissAll}
                                                style={{marginTop: '15px'}}>
                                            <FontAwesomeIcon icon={solid("trash")}/>
                                            <span> Clear All</span>
                                        </button>
                                        {notifications.map((notification) => {
                                            return <Notification key={notification.id}
                                                                 dismiss={() => DismissNotification(notification.id, () => {
                                                                     setNotifications(notifications.filter(n => n.id !== notification.id))
                                                                     setNotificationCount(notificationCount - 1)
                                                                 })}
                                                                 subject={notification.subject} page={notification.page}
                                                                 time={notification.time}
                                                                 close={() => setShowNotifications(false)}>{notification.body}</Notification>
                                        })}
                                        {(notifications.length < notificationCount) &&
                                            <button className={"secondary-button notification-load-button"}
                                                    onClick={LoadNewBatch}
                                                    style={{marginBottom: '15px'}}>
                                                <FontAwesomeIcon icon={solid("plus")}/>
                                                <span> Load More</span>
                                            </button>}
                                    </>

                                    :
                                    <p className={"notification-empty-notice"}>No notifications!</p>}
                            </DropDown>
                            <button className={"nav-icon-button"} onClick={() => setShowNotifications(true)} title={"Notifications"}>
                                <FontAwesomeIcon icon={(showNotifications ? solid("bell") : regular("bell"))}
                                                 style={{color: "var(--font-colour)", height: '22px'}}/>
                                {notificationCount > 0 &&
                                    <div
                                        className={"notification-badge"}>{notificationCount < 10 ? notificationCount : "9+"}</div>}
                            </button>
                        </div>
                        <div className={"account-container"} >
                            <button className="profile-button" title={handle} onClick={() => setShowProfileDropDown(!showProfileDropDown)}>
                                <img className={"nav-profile-picture"} width={32} height={32}
                                     src={profilePicture || defaultPicture}/>
                            </button>
                            <DropDown showing={showProfileDropDown} close={() => setShowProfileDropDown(false)}>
                                <DropDownItem  onClick={() => {
                                }}>
                                    <Link to={`/user/${handle}`}>
                                        <FontAwesomeIcon icon={solid("user")} style={{color: "var(--font-colour)"}}/>
                                        <span style={{color: "var(--font-colour)"}}> Profile</span>
                                    </Link>
                                </DropDownItem>
                                <DropDownItem onClick={() => {
                                }}>
                                    <Link to={`/settings`}>
                                        <FontAwesomeIcon icon={solid("gear")} style={{color: "var(--font-colour)"}}/>
                                        <span style={{color: "var(--font-colour)"}}> Settings</span>
                                    </Link>
                                </DropDownItem>
                                <DropDownItem onClick={Logout}>
                                    <FontAwesomeIcon icon={solid("door-open")} style={{color: "red"}}/>
                                    <span style={{color: "red"}}> Log Out</span>
                                </DropDownItem>
                            </DropDown>
                        </div>
                    </>
                    :
                    <>
                        <Link to={"/settings"} title={"Settings"}>
                            <button className={"nav-icon-button"}>
                                <FontAwesomeIcon icon={(solid("gear"))}
                                                 style={{
                                                     color: "var(--font-colour)",
                                                     transform: 'scaleX(-1)',
                                                     height: '22px'
                                                 }}/>
                            </button>
                        </Link>
                        <Link to={"/login"} title={"Log In"}>
                            <button className={"nav-icon-button"}>
                                <FontAwesomeIcon icon={(solid("user"))}
                                                 style={{color: "var(--font-colour)", height: '22px'}}/>
                            </button>
                        </Link>
                    </>}
                {(isMobile && showMobileSearchBar) &&
                    <form onSubmit={handleSubmit} className="mobile-search-bar">
                        <input className={"mobile-search-input"} id="search-bar" type="text"
                               onChange={(e) => setSearchInput(e.target.value)} placeholder="Search..."/>
                        <button type={"submit"}
                                className={`mobile-search-button ${searchInput === "" ? "disabled" : ""}`}>
                            <FontAwesomeIcon icon={solid("magnifying-glass")}
                                             style={{color: "inherit", height: "100%", maxHeight: "100%"}}/>
                        </button>
                    </form>

                }
            </div>
        </nav>
    )
        ;
}