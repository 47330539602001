import '../stylesheet/post-pages.css'
import {Navigate, useNavigate, useParams} from "react-router";
import {useAuth} from "./login-signup-page";
import React, {useEffect, useState} from "react";
import {
    DeletePost,
    FetchAverageRating,
    FetchDetails,
    FormatTime,
    FormatTimeDifference, FormatUserPostText, HapticTap, ImagePopup,
    Star, SubmitLike,
    SubmitRating, UpdateLike, UpdateRating
} from "./home-page";
import {useNotifications} from "../components/navbar";
import {GetProfilePicture} from "./profile-page";
import {Link} from "react-router-dom";
import {DropDown, DropDownItem} from "../components/dropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {regular, solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {DefaultPage} from "./default-page";
import CloseButton from "../components/close-button";
import testPfp from "../media/default-picture.jpg";


const StarRow = ({rating, count}) => {
    return (<div className={"analytics-star-row"}>
        {Array.from({length: 5}).map((_, index) => <Star key={index} rating={rating} index={index + 1}/>)}
        <span className={"analytics-star-count"}>{count}</span>
    </div>)
}

export const PostAnalyticsPage = ({}) => {
    const {handle} = useAuth()
    const {id} = useParams()
    const {setPlainTitle} = useNotifications()
    const [details, setDetails] = useState(null)
    useEffect(() => {
        setPlainTitle("Post Analytics | SketchFlow")
    }, []);

    async function GetStatistics() {
        const res = await fetch(`https://sketchflow.io/api/posts/${id}/statistics`, {
            headers: {
                "Authorization": localStorage.getItem("jwt"),
            },
        });

        const resData = await res.json();

        console.log(resData)
        if (res.ok) {
            setDetails(resData.result)
        }
    }

    useEffect(() => {
        if (!parseInt(id)) return
        GetStatistics((result) => {
            setDetails(result)
        })
    }, [id])

    if (!parseInt(id)) return <Navigate to={"/"}/>
    const intId = parseInt(id)

    if (!details) {
        return (<></>)
    }
    return <div className={"post-details-page"}>
        <div className={"post-details-container"}>
            <span className={"heading"}>Post Analytics</span>

            <span className={"subheading"}>Views</span>
            <span className={"analytics-stat-number"}>{details.views}</span>

            <span className={"subheading"}>Comments</span>
            <span className={"analytics-stat-number"}>{details.comments}</span>

            <span className={"subheading"}>Ratings</span>
            <span className={"analytics-stat-number"}>{details.ratings.total}</span>

            <StarRow rating={5} count={details.ratings["5"]}/>
            <StarRow rating={4} count={details.ratings["4"]}/>
            <StarRow rating={3} count={details.ratings["3"]}/>
            <StarRow rating={2} count={details.ratings["2"]}/>
            <StarRow rating={1} count={details.ratings["1"]}/>

            <span className={"subheading"}>Average</span>
            <span className={"analytics-stat-number"}>
                {details.ratings.average}
            </span>
        </div>

    </div>
}

export const PostEditPage = ({}) => {
    const {handle} = useAuth()
    const {id} = useParams()
    const {setPlainTitle} = useNotifications()
    useEffect(() => {
        setPlainTitle("Edit Post | SketchFlow")
    }, []);

    if (!parseInt(id)) return <Navigate to={"/"}/>
    const intId = parseInt(id)
    return <div className={"post-details-page"}>
        <div className={"post-details-container"}>
            <span className={"heading"}>Edit Post</span>
            <span>{intId} {handle}</span>
        </div>
    </div>
}

async function FetchEligibility(handle, callback) {
    const res = await fetch(`https://sketchflow.io/api/user/${handle}/post_eligibility`, {
        headers: {
            'Authorization': localStorage.getItem('jwt')
        }
    });

    const resData = await res.json();
    if (res.ok) {
        callback(resData.result);
    } else {
        callback(false);
    }
}

const CommentSection = ({id}) => {
    const {handle, loggedIn} = useAuth();
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isEligible, setIsEligible] = useState(false);
    const [totalComments, setTotalComments] = useState(0);
    const [page, setPage] = useState(1);
    const [atBottom, setAtBottom] = useState(false);
    const [commentPrefix, setCommentPrefix] = useState("");
    const [visibleReplies, setVisibleReplies] = useState([]);
    const [currentReply, setCurrentReply] = useState(-1);
    const [replies, setReplies] = useState([]);

    useEffect(() => {
        LoadComments(page)
    }, [])

    useEffect(() => {
        if (handle === "") return;
        FetchEligibility(handle, (result) => {
            setIsEligible(result)
        })
    }, [handle])

    async function LoadReplies(commentId) {
        const comment = comments.filter(c => c.comment_id === commentId)[0];
        if (!comment) return;

        const existing = replies.filter(r => r.comment_id === commentId)[0];
        console.log(existing,comment.total_replies)
        if (existing && existing.replies.length >= comment.total_replies) {
            return;
        }
        const res = await fetch(`https://sketchflow.io/api/posts/${id}/comments/${commentId}/replies`);

        const resData = await res.json();

        if (res.ok) {
            console.log("ok")
            if (existing) {
                setReplies(replies => {
                    return replies.map(comment => {
                        if (comment.comment_id === commentId) {
                            return {
                                ...comment,
                                total: resData.total,
                                replies: resData.replies
                            };
                        }
                        return comment;
                    });

                });
            } else{
                setReplies(replies => [
                    ...replies,
                    {
                        comment_id: commentId,
                        total: resData.total,
                        replies: resData.replies
                    }
                ]);
            }

        }
    }

    function ShowReplies(id) {
        setVisibleReplies(replies => [
            ...replies,
            id
        ])
        LoadReplies(id)
    }

    function HideReplies(id) {
        setVisibleReplies(replies => replies.filter(replyId => replyId !== id));
    }


    async function LoadComments(page) {
        const res = await fetch(`https://sketchflow.io/api/posts/${id}/comments?page=${page}`);

        const resData = await res.json();

        if (res.ok) {
            setAtBottom(comments.length + resData.comments.length === resData.total)

            setComments([...comments, ...resData.comments])
            setTotalComments(resData.total)
        }
        setLoading(false)
    }

    function LoadNewBatch() {
        LoadComments(page + 1)
        setPage(page + 1)
    }

    function RemoveComment(id) {
        setComments(comments.filter(c => c.comment_id !== id))
        setTotalComments(totalComments - 1)
    }

    function RemoveReply(id, parent) {
        setReplies(replies => {
            return replies.map(comment => {
                if (comment.comment_id === parent) {
                    const updatedReplies = comment.replies.filter(r => r.reply_id !== id);
                    return {
                        ...comment,
                        total: comment.total - 1,
                        replies: updatedReplies
                    };
                }
                return comment;
            });

        });
        setComments(comments => {
            return comments.map(comment =>
                comment.comment_id === parent
                    ? {
                        ...comment,
                        total_replies: comment.total_replies - 1
                    }
                    : comment
            )
        })
    }

    function MentionComment(handle, id) {
        setCurrentReply(id)
        const input = document.getElementById(`post-reply-input-${id}`)
        if (!input) return;
        input.value = "@" + handle + " "
        input.focus()
    }

    function AddComment(id, body) {
        setTotalComments(totalComments + 1)
        setComments(cmts => [
            {
                comment_id: id,
                user_handle: handle,
                content: body,
                time: Date.now()
            },
            ...cmts
        ])
    }

    function AddReply(id, parent, body) {
        ShowReplies(parent)
        setComments(comments => {
            return comments.map(comment =>
                comment.comment_id === parent
                    ? {
                        ...comment,
                        total_replies: comment.total_replies + 1
                    }
                    : comment
            )
        })
        setReplies(prevReplies => {
            const commentExists = prevReplies.some(comment => comment.comment_id === parent);

            if (commentExists) {
                return prevReplies.map(comment =>
                    comment.comment_id === parent
                        ? {
                            ...comment,
                            total: comment.total + 1,
                            replies: [...comment.replies, {
                                reply_id: id,
                                user_handle: handle,
                                content: body,
                                time: Date.now()
                            }]
                        }
                        : comment
                );
            } else {
                return [
                    ...prevReplies,
                    {
                        comment_id: parent,
                        total: 1,
                        replies: [{
                            reply_id: id,
                            user_handle: handle,
                            content: body,
                            time: Date.now()
                        }]
                    }
                ];
            }
        });
    }

    return (<div className={"post-comment-container"}>
        <div className={"subheading"}>Comments <span className={"post-stat-detail"}>{totalComments}</span></div>
        <CommentInput eligible={isEligible} loggedIn={loggedIn} add={AddComment} id={id} commentPrefix={commentPrefix}
                      setCommentPrefix={setCommentPrefix}/>
        {(comments.length === 0) ?
            <div className={"post-comment-content empty"}>
                <span style={{marginTop: '20px', color: "gray"}}>No comments!</span>
            </div>
            : <div className={"post-comment-content"}>
                {comments.map((comment, i) => {
                    return (<article className={"post-comment-wrapper"} key={i}>
                        <Comment id={comment.comment_id} remove={RemoveComment}
                                 author_handle={comment.user_handle}
                                 body={FormatUserPostText(comment.content)} timestamp={comment.time}
                                 reply={setCurrentReply}
                                 total_likes={comment.total_likes}
                                 eligible={isEligible} loggedIn={loggedIn}/>
                        <ReplyInput submit={(id, content) => AddReply(id, currentReply, content)}
                                    parentId={comment.comment_id} visible={currentReply === comment.comment_id} postId={id} cancel={() => setCurrentReply(-1)}/>
                        <div className={"post-comment-show-replies"} onClick={() => {
                            visibleReplies.includes(comment.comment_id) ? HideReplies(comment.comment_id) : ShowReplies(comment.comment_id)
                        }}>
                            {comment.total_replies > 0 && (visibleReplies.includes(comment.comment_id) ?
                                <>
                                    <FontAwesomeIcon icon={solid("chevron-up")}/>
                                    <span style={{marginLeft: '5px'}}>Hide replies</span>
                                </>
                                :
                                <>
                                    <FontAwesomeIcon icon={solid("chevron-down")}/>
                                    <span style={{marginLeft: '5px'}}>Show replies ({comment.total_replies})</span>
                                </>)}
                        </div>
                        {visibleReplies.includes(comment.comment_id) &&
                            <>
                                {replies.some(r => r.comment_id === comment.comment_id) && replies.filter(r => r.comment_id === comment.comment_id)[0]?.replies.map((reply, i) => {
                                    return <Reply key={i} id={reply.reply_id} comment_id={comment.comment_id} remove={RemoveReply}
                                                  author_handle={reply.user_handle}
                                                  body={FormatUserPostText(reply.content)} timestamp={reply.time}
                                                  total_likes={reply.total_likes}
                                                  reply={(handle) => MentionComment(handle, comment.comment_id)}
                                                  eligible={isEligible} loggedIn={loggedIn}/>
                                })}
                            </>}
                    </article>)
                })}

                {loading &&
                    <FontAwesomeIcon icon={solid("spinner")} spin style={{color: "var(--font-colour)"}}/>}
                {!atBottom &&
                    <button className={"secondary-button comment-load-button"} onClick={LoadNewBatch}
                            style={{marginBottom: '15px'}}>
                        <FontAwesomeIcon icon={solid("plus")}/>
                        <span> Load More</span>
                    </button>}
            </div>}

    </div>)
}

const Reply = ({id, comment_id, total_likes, author_handle, remove, body, timestamp, reply, loggedIn, eligible}) => {
    const [showCommentDropDown, setShowCommentDropDown] = useState(false);
    const {handle} = useAuth();
    const [pictureUrl, setPictureUrl] = useState(testPfp);
    const [isLiked, setIsLiked] = useState(false);
    const navigate = useNavigate()

    const [totalLikes, setTotalLikes] = useState(total_likes)

    async function DeleteReply() {
        const res = await fetch(`https://sketchflow.io/api/comments/${comment_id}/replies/${id}`, {
            method: "DELETE",
            headers: {
                'Authorization': localStorage.getItem('jwt')
            }
        });

        await res.json();
        if (res.ok) {
            remove(id, comment_id)
        }
    }

    async function UpdateReplyLike() {
        const res = await fetch(`https://sketchflow.io/api/comments/${comment_id}/replies/${id}/like`, {
            headers: {
                'Authorization': localStorage.getItem('jwt')
            }
        });

        const resData = await res.json();
        if (res.ok) {
            setIsLiked(resData.isLiked)
        } else {
            setIsLiked(false);
        }
    }

    async function SubmitReplyLike(isLiked) {
        isLiked ? setTotalLikes(totalLikes + 1) : setTotalLikes(totalLikes - 1)
        setIsLiked(isLiked);
        const res = await fetch(`https://sketchflow.io/api/comments/${comment_id}/replies/${id}/like`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('jwt')
            },
            body: JSON.stringify({
                liked: isLiked
            })
        });

        await res.json();
        if (res.ok) {
            setIsLiked(isLiked);
        } else {
            setIsLiked(!isLiked)
        }
    }

    function ReplyComment() {
        reply(author_handle)
    }

    useEffect(() => {
        UpdateReplyLike(id, setIsLiked)
    })

    useEffect(() => {
        GetProfilePicture(author_handle, (picture) => {
            setPictureUrl(picture)
        })
    }, [author_handle])

    const timeString = FormatTimeDifference(timestamp, Date.now());
    return (<div className={"post-comment-reply"}>
        <div className={"post-author-container"}>
            <Link to={`/user/${author_handle}`}>
                <img className="post-author-image" draggable={false} src={pictureUrl} alt=""/>
            </Link>
            <Link to={`/user/${author_handle}`}>
                <span className="post-author-name">{author_handle}</span>
            </Link>

            <span className="post-timestamp" title={FormatTime(timestamp)}>{timeString}</span>
            {author_handle === handle ?
                <div className={"post-edit-container"}>
                    <DropDown showing={showCommentDropDown} close={() => setShowCommentDropDown(false)}>
                        <DropDownItem onClick={DeleteReply}>
                            <FontAwesomeIcon icon={solid("trash")} style={{color: "red"}}/>
                            <span style={{color: "red"}}> Delete</span>
                        </DropDownItem>
                    </DropDown>
                    <button onClick={() => setShowCommentDropDown(!showCommentDropDown)} className={"post-edit-button"}>
                        <FontAwesomeIcon icon={solid("ellipsis-vertical")}/>
                    </button>
                </div>
                :
                <div className={"post-edit-container"}>
                    <DropDown showing={showCommentDropDown} close={() => setShowCommentDropDown(false)}>
                        <DropDownItem onClick={() => console.log("report")}>
                            <FontAwesomeIcon style={{color: "var(--font-colour)"}} icon={solid("flag")}/>
                            <span style={{color: "var(--font-colour)"}}> Report</span>
                        </DropDownItem>
                    </DropDown>
                    <button onClick={() => setShowCommentDropDown(!showCommentDropDown)} className={"post-edit-button"}>
                        <FontAwesomeIcon icon={solid("ellipsis-vertical")}/>
                    </button>
                </div>}
        </div>
        <span>{body}</span>
        <div className={"comment-actions-container"}>
            <div className={"comment-like-container"}>
                <button onClick={() => (loggedIn) ? SubmitReplyLike(!isLiked) : navigate("/login")}
                        className={`comment-action-button heart ${isLiked ? "active" : ""}`}>
                    {isLiked ?
                        <FontAwesomeIcon icon={solid("heart")}/>
                        :
                        <FontAwesomeIcon icon={regular("heart")}/>}
                </button>
                <span className={"post-action-label"}>{totalLikes}</span>
            </div>


            {(loggedIn && eligible) &&
                <button onClick={ReplyComment} className={"comment-action-button"}>
                    Reply
                </button>}
        </div>
    </div>)
}

const Comment = ({id, author_handle, remove, body, total_likes, timestamp, reply, eligible, loggedIn}) => {
    const [showCommentDropDown, setShowCommentDropDown] = useState(false);
    const {handle} = useAuth();
    const [pictureUrl, setPictureUrl] = useState(testPfp);
    const [isLiked, setIsLiked] = useState(false);
    const navigate = useNavigate()

    const [totalLikes, setTotalLikes] = useState(total_likes)

    async function DeleteComment() {
        const res = await fetch(`https://sketchflow.io/api/comments/${id}`, {
            method: "DELETE",
            headers: {
                'Authorization': localStorage.getItem('jwt')
            }
        });

        await res.json();
        if (res.ok) {
            remove(id)
        }
    }

    async function UpdateCommentLike() {
        const res = await fetch(`https://sketchflow.io/api/comments/${id}/like`, {
            headers: {
                'Authorization': localStorage.getItem('jwt')
            }
        });

        const resData = await res.json();
        if (res.ok) {
            setIsLiked(resData.isLiked)
        } else {
            setIsLiked(false);
        }
    }

    async function SubmitCommentLike(isLiked) {
        isLiked ? setTotalLikes(totalLikes + 1) : setTotalLikes(totalLikes - 1)
        setIsLiked(isLiked);
        const res = await fetch(`https://sketchflow.io/api/comments/${id}/like`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('jwt')
            },
            body: JSON.stringify({
                liked: isLiked
            })
        });

        await res.json();
        if (res.ok) {
            setIsLiked(isLiked);
        } else {
            setIsLiked(!isLiked)
        }
    }

    function ReplyComment() {
        reply(id)
    }

    useEffect(() => {
        GetProfilePicture(author_handle, (picture) => {
            setPictureUrl(picture)
        })
    }, [author_handle])

    useEffect(() => {
        UpdateCommentLike(id, setIsLiked)
    })

    const timeString = FormatTimeDifference(timestamp, Date.now());
    return (<div className={"post-comment"}>
        <div className={"post-author-container"}>
            <Link to={`/user/${author_handle}`}>
                <img className="post-author-image" draggable={false} src={pictureUrl} alt=""/>
            </Link>
            <Link to={`/user/${author_handle}`}>
                <span className="post-author-name">{author_handle}</span>
            </Link>

            <span className="post-timestamp" title={FormatTime(timestamp)}>{timeString}</span>
            {/*{Array.from({length: 5}).map((_, index) => <Star key={index} rating={rating} index={index + 1}/>)}*/}
            {author_handle === handle ?
                <div className={"post-edit-container"}>
                    <DropDown showing={showCommentDropDown} close={() => setShowCommentDropDown(false)}>
                        <DropDownItem onClick={DeleteComment}>
                            <FontAwesomeIcon icon={solid("trash")} style={{color: "red"}}/>
                            <span style={{color: "red"}}> Delete</span>
                        </DropDownItem>
                    </DropDown>
                    <button onClick={() => setShowCommentDropDown(!showCommentDropDown)} className={"post-edit-button"}>
                        <FontAwesomeIcon icon={solid("ellipsis-vertical")}/>
                    </button>
                </div>
                :
                <div className={"post-edit-container"}>
                    <DropDown showing={showCommentDropDown} close={() => setShowCommentDropDown(false)}>
                        <DropDownItem onClick={() => console.log("report")}>
                            <FontAwesomeIcon style={{color: "var(--font-colour)"}} icon={solid("flag")}/>
                            <span style={{color: "var(--font-colour)"}}> Report</span>
                        </DropDownItem>
                    </DropDown>
                    <button onClick={() => setShowCommentDropDown(!showCommentDropDown)} className={"post-edit-button"}>
                        <FontAwesomeIcon icon={solid("ellipsis-vertical")}/>
                    </button>
                </div>}

        </div>
        <span>{body}</span>
        <div className={"comment-actions-container"}>
            <div className={"comment-like-container"}>
                <button onClick={() => (loggedIn) ? SubmitCommentLike(!isLiked) : navigate("/login")}
                        className={`comment-action-button heart ${isLiked ? "active" : ""}`}>
                    {isLiked ?
                        <FontAwesomeIcon icon={solid("heart")}/>
                        :
                        <FontAwesomeIcon icon={regular("heart")}/>}
                </button>
                <span className={"post-action-label"}>{totalLikes}</span>
            </div>


            {(loggedIn && eligible) &&
                <button onClick={ReplyComment} className={"comment-action-button"}>
                    Reply
                </button>}
        </div>
    </div>)
}

const ReplyInput = ({postId, parentId, visible, submit, cancel}) => {
    const [reply, setReply] = useState("");
    const [loading, setLoading] = useState(false);

    async function SubmitReply(e) {
        e.preventDefault()
        if (reply === "") return;
        setLoading(true)
        const res = await fetch(`https://sketchflow.io/api/posts/${postId}/comments/${parentId}/replies`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('jwt')
            },
            body: JSON.stringify({
                content: reply
            })
        });

        const resData = await res.json();
        if (res.ok) {
            if (document.getElementById(`post-reply-input-${parentId}`))
                document.getElementById(`post-reply-input-${parentId}`).value = ""
            submit(resData.comment.id, reply)
            setReply("")
            cancel()
        }
        setLoading(false)
    }

    return (<form className={`text-box-form post-reply-input-container ${visible ? "active" : ""}`} onSubmit={SubmitReply}>
        <input id={`post-reply-input-${parentId}`} type={"text"} onChange={(e) => setReply(e.target.value)}
               placeholder={"Reply..."}
               className={"text-box-input"}
               autoComplete={"off"}/>

        {loading ?
            <button className={"text-box-submit disabled"}>
                <FontAwesomeIcon icon={solid("spinner")} spin/>
            </button>
            :
            <button type={"submit"} className={`text-box-submit` + (reply === "" ? " disabled" : "")}>
                <FontAwesomeIcon icon={solid("comment")}/>
                <span style={{marginLeft: '5px'}}> Post</span>
            </button>}
        <button className={`text-box-submit`} onClick={cancel}>
            <FontAwesomeIcon icon={solid("trash")}/>
        </button>
    </form>)
}

const CommentInput = ({eligible, loggedIn, commentPrefix, setCommentPrefix, add, id}) => {
    const [comment, setComment] = useState("");
    const [loading, setLoading] = useState(false);

    async function SubmitComment(e) {
        e.preventDefault()
        if (comment === "") return;
        setLoading(true)
        const res = await fetch(`https://sketchflow.io/api/posts/${id}/comments`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('jwt')
            },
            body: JSON.stringify({
                content: comment
            })
        });

        const resData = await res.json();
        console.log(resData)
        if (res.ok) {
            if (document.getElementById("post-comment-input"))
                document.getElementById("post-comment-input").value = ""
            add(resData.comment.id, comment)
            setComment("")
        }
        setLoading(false)
    }

    useEffect(() => {
        if (!commentPrefix || !setCommentPrefix) return;
        if (commentPrefix === "") return;

        setComment(commentPrefix)
        document.getElementById("post-comment-input").value = commentPrefix
        document.getElementById("post-comment-input").focus()
        setCommentPrefix("")
    }, [commentPrefix])

    if (!loggedIn) {
        return (<div className={"post-comment-input-container ineligible"}>
            <Link to={"/login"}>
                <span className={"post-verify-email"}>Log in to comment</span>
            </Link>
        </div>)
    }
    if (!eligible) {
        return (<div className={"post-comment-input-container ineligible"}>
            <Link to={"/settings"}>
                <span className={"post-verify-email"}>Verify your email to comment</span>
            </Link>
        </div>)
    }
    return (<form className={"text-box-form post-comment-input-container"} onSubmit={SubmitComment}>
        <input id={"post-comment-input"} type={"text"} onChange={(e) => setComment(e.target.value)}
               placeholder={"Comment..."}
               className={"text-box-input"}
               autoComplete={"off"}/>

        {loading ?
            <button className={"text-box-submit disabled"}>
                <FontAwesomeIcon icon={solid("spinner")} spin/>
            </button>
            :
            <button type={"submit"} className={`text-box-submit` + (comment === "" ? " disabled" : "")}>
                <FontAwesomeIcon icon={solid("comment")}/>
                <span style={{marginLeft: '5px'}}> Post</span>
            </button>}

    </form>)
}

export const PostPage = ({}) => {
    const {handle, loggedIn} = useAuth()
    const {id} = useParams()
    const {setPlainTitle} = useNotifications()
    const [details, setDetails] = useState(null)
    const [showOwnerDropDown, setShowOwnerDropDown] = useState(false);
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [isLiked, setIsLiked] = useState(false)
    const [rating, setRating] = useState(0)
    const [averageRating, setAverageRating] = useState(0)
    const [showEnlarged, setShowEnlarged] = useState(false);
    const [truncateDescription, setTruncateDescription] = useState(true);

    const isPostOwner = () => {
        if (!details) return;
        return details.author.handle === handle;
    }

    useEffect(() => {
        if (!handle || !isPostOwner()) return;

        FetchAverageRating(handle, id, (avg) => {
            setAverageRating(Math.round(avg * 10) / 10)
        })
    })

    useEffect(() => {
        setLoading(true)
        UpdateRating(id, setRating);
        UpdateLike(id, setIsLiked)
        FetchDetails(id).then((res) => {
            setDetails(res)
            setLoading(false)
        });
    }, [id])

    useEffect(() => {
        if (!details) {
            setPlainTitle("Post | SketchFlow")
            return;
        }
        setPlainTitle(`${details.title} | SketchFlow`)
    }, [details]);

    if (loading) return (<div className={"post-page loading"}>
        <FontAwesomeIcon icon={solid("spinner")} spin/>
    </div>)
    if (!details) return (<DefaultPage/>)

    const maxDescLength = 180;
    let description = details.description;
    if (truncateDescription && description.length >= 180) description = description.substring(0, Math.min(maxDescLength, description.length)) + "...";

    return (<div className={"post-page"}>
        {showEnlarged && <ImagePopup src={details.image_url} onClose={() => setShowEnlarged(false)}/>}
        <div className={"post-page-container"}>
            <div className={"post-author-container"}>
                <Link to={`/user/${details.author.handle}`}>
                    <img className="post-author-image" draggable={false} src={details.author.picture_url} alt=""/>
                </Link>
                <Link to={`/user/${details.author.handle}`}>
                    <span className="post-author-name">{details.author.handle}</span>
                </Link>

                <span className="post-timestamp"
                      title={FormatTime(details.time)}>{FormatTimeDifference(details.time, Date.now())}</span>

                {isPostOwner() &&
                    <div className={"post-edit-container"}>
                        <DropDown showing={showOwnerDropDown} close={() => setShowOwnerDropDown(false)}>
                            <DropDownItem onClick={() => {
                            }}>
                                <Link to={`/post/${id}`}>
                                    <FontAwesomeIcon icon={solid("pencil")} style={{color: "var(--font-colour)"}}/>
                                    <span style={{color: "var(--font-colour)"}}> Edit</span>
                                </Link>
                            </DropDownItem>
                            <DropDownItem onClick={() => {
                            }}>
                                <Link to={`/post/${id}/analytics`}>
                                    <FontAwesomeIcon icon={solid("eye")} style={{color: "var(--font-colour)"}}/>
                                    <span style={{color: "var(--font-colour)"}}> Analytics</span>
                                </Link>
                            </DropDownItem>
                            <DropDownItem onClick={() => DeletePost(id)}>
                                <FontAwesomeIcon icon={solid("trash")} style={{color: "red"}}/>
                                <span style={{color: "red"}}> Delete</span>
                            </DropDownItem>
                        </DropDown>
                        <button onClick={() => setShowOwnerDropDown(!showOwnerDropDown)} className={"post-edit-button"}>
                            <FontAwesomeIcon icon={solid("ellipsis-vertical")}
                                             style={{color: "var(--font-colour)"}}/>
                        </button>
                    </div>}
            </div>
            <div className={"post-page-title heading"}>{details.title}</div>
            <div className={"post-content-container"}>
                <img className={"post-content"} onClick={() => setShowEnlarged(true)} src={details.image_url}/>
            </div>
            {loggedIn ? <div className="post-rating-container">
                    <div className={"post-action-container like"}>
                        <FontAwesomeIcon onClick={() => {
                            isLiked ? details.likes -= 1 : details.likes += 1
                            SubmitLike(id, !isLiked, setIsLiked)
                            HapticTap()
                        }}
                                         className={`post-action-button heart ${isLiked ? "active" : ""}`}
                                         icon={isLiked ? solid("heart") : regular("heart")}/>
                        <div className={"post-action-label"}>{details.likes}</div>
                    </div>
                    {!isPostOwner() ? Array.from({length: 5}).map((_, index) => <Star key={index} rating={rating}
                                                                                      index={index + 1}
                                                                                      setRating={(r) => SubmitRating(id, r, setRating)}/>) :
                        Array.from({length: 5}).map((_, index) => <Star key={index}
                                                                        rating={averageRating}
                                                                        index={index + 1}/>)
                    }
                    <span
                        className={"post-average-rating"}>{(isPostOwner() && typeof averageRating === "number") && `(${averageRating})`}</span>
                </div>
                :
                <div className="post-rating-container">
                    <div className={"post-action-container like"}>
                        <FontAwesomeIcon onClick={() => navigate('/login')}
                                         className={`post-action-button heart disabled`}
                                         icon={isLiked ? solid("heart") : regular("heart")}/>
                        <div className={"post-action-label"}>{details.likes}</div>
                    </div>
                    {Array.from({length: 5}).map((_, index) => <Star key={index}
                                                                     rating={0}
                                                                     index={index + 1}/>)}
                </div>}
            {details.description.length > 0 && <>
                <div className={"subheading"}>Description</div>
                <span
                    className={`body post-description ${(details.description.length >= maxDescLength) && "oversized"}`}
                    onClick={() => setTruncateDescription(!truncateDescription)}>{FormatUserPostText(description)}</span></>}
            <CommentSection id={id}/>
        </div>
    </div>)
}