import {useParams} from "react-router";
import {Link, useLocation} from "react-router-dom";
import '../stylesheet/search-page.css'
import React, {useEffect, useState} from "react";
import {useNotifications} from "../components/navbar";
import {ArtContainer} from "./home-page";

import {GetProfileDetails, GetProfilePicture} from "../pages/profile-page";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


async function FetchSearch(query, type, current, page, callback) {
    const resp = await fetch(`https://sketchflow.io/api/search`, {
        method: 'POST',
        headers: {
            'Authorization': localStorage.getItem('jwt'),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query: query,
            type: type,
            page: page,
            current: current,
        })
    });

    const resData = await resp.json();
    if (resp.ok) {
        callback(resData.result) // this looks like a mess lol
        return;
    }
    console.error("Error fetching");
}

export const UserContainer = ({userHandle}) => {
    const [details, setDetails] = useState(null);
    const [picture, setPicture] = useState(null);

    useEffect(() => {
        if (typeof userHandle !== "string") return;
        GetProfileDetails(userHandle, (data) => {
            setDetails(data)
        });
        GetProfilePicture(userHandle, (picture) => {
            setPicture(picture)
        })
    }, [userHandle])


    if (typeof userHandle !== "string") {
        return (<></>)
    }
    if (!details) {
        return (<></>)
    }

    const profileLink = `/user/${userHandle}`
    return (
        <article className="user-card-container">

            <div className={"user-card-left"}>
                <Link to={profileLink}>
                    <img className={"user-card-picture"} src={picture}/>
                </Link>
            </div>
            <div className={"user-card-right"}>
                <a href={profileLink} className="user-card-handle">@{userHandle}</a>
                <Link to={profileLink}>
                    <button className={"secondary-button user-card-button"}>Go to Profile</button>
                </Link>
            </div>
        </article>
    )
}

export const SearchPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get('q');

    const {setPlainTitle} = useNotifications()
    const [searchType, setSearchType] = useState("posts");
    const [results, setResults] = useState([]);
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setPlainTitle(`${query} | SketchFlow`)
        setLoading(true)
        setPage(1)
        setResults([])
        FetchSearch(query, searchType, results, page, (items) => {
            setResults(items.results)
            setLoading(false)
        })
    }, [searchType]);

    useEffect(() => {
        setPlainTitle(`${query} | SketchFlow`)
        setLoading(true)
        setPage(1)
        setResults([])
        FetchSearch(query, searchType, results, page, (items) => {
            setResults(items.results)
            setLoading(false)
        })
    }, [query]);


    return (<div className={"search-page"}>
        <div className={"search-container"}>
            <div className={"search-type-container"}>
                <button className={`search-type ${searchType === "posts" && "selected"}`}
                        onClick={() => setSearchType("posts")}>Posts
                </button>
                <button className={`search-type ${searchType === "users" && "selected"}`}
                        onClick={() => setSearchType("users")}>Users
                </button>
            </div>
            <div className={"heading"}>{searchType === "posts" ? "Post" : "User"} Results for</div>
            <div className={"body"}>{query}</div>


            {searchType === "posts" && <div className={`search-results-container ${results.length === 0 && "empty"}`}>
                {loading &&
                    <FontAwesomeIcon spin icon={solid("spinner")}/>}
                {results.length === 0 && !loading ?
                    <span style={{marginTop: '20px', color: "gray"}}>No results</span>
                    :
                    results.map((result, _) => (
                        <ArtContainer key={result} id={result}/>
                    ))}
            </div>}
            {searchType === "users" && <div className={`search-results-container ${results.length === 0 && "empty"}`}>
                {loading &&
                    <FontAwesomeIcon spin icon={solid("spinner")}/>}
                {results.length === 0 && !loading ?
                    <span style={{marginTop: '20px', color: "gray"}}>No results</span>
                    :
                    results.map((result, _) => (
                        <UserContainer key={result} userHandle={result}/>
                    ))}
            </div>}
        </div>
    </div>)
}