import React, {useCallback, useEffect, useRef, useState} from 'react';
import '../stylesheet/home-page.css'
import {useAuth} from "./login-signup-page";
import {useNavigate, useParams} from "react-router";
import {regular, solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import testPfp from "../media/default-picture.jpg";
import {DropDown, DropDownItem} from "../components/dropdown";
import {GetProfilePicture} from "./profile-page";
import CloseButton from "../components/close-button";
import {Link} from "react-router-dom";
import {useNotifications} from "../components/navbar";
import _debounce from 'lodash/debounce';
import {debounce} from "lodash";
import {post} from "axios";


export function FormatTime(date) {
    const options = {
        hour: '2-digit',
        minute: '2-digit',
        day: '2-digit',
        month: 'short',
        year: 'numeric'
    };

    return new Intl.DateTimeFormat('en-US', options).format(date);
}

export function FormatTimeOnly(date) {
    const options = {
        hour: '2-digit',
        minute: '2-digit'
    };

    return new Intl.DateTimeFormat('en-US', options).format(date);
}

export function HapticTap() {
    if (Boolean(window.navigator.vibrate)) {
        window.navigator.vibrate(100)
    }
}

export function FormatUserPostText(text) {
    const mentionRegex = /(@\w+)/g;
    const validUsername = /^[a-z0-9]{3,20}$/;

    const parts = text.split(mentionRegex);

    return (
      parts.map((part, index) =>
          mentionRegex.test(part) ? (
              validUsername.test(part.slice(1)) ? (
                  <Link key={index} to={`/user/${part.slice(1)}`} className="text-user-mention">
                      {part}
                  </Link>
              ) : (
                  part
              )
          ) : (
              part
          )
      )
    );
}

export function FormatTimeDifference(timestamp1, timestamp2) {
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);

    const timeDiff = Math.abs(date2 - date1);

    const units = [
        {label: "d", milliseconds: 86400000}, // 1 day = 24 hours * 60 minutes * 60 seconds * 1000 milliseconds
        {label: "h", milliseconds: 3600000},  // 1 hour = 60 minutes * 60 seconds * 1000 milliseconds
        {label: "m", milliseconds: 60000},    // 1 minute = 60 seconds * 1000 milliseconds
        {label: "s", milliseconds: 1000}      // 1 second = 1000 milliseconds
    ];

    for (const unit of units) {
        const count = Math.floor(timeDiff / unit.milliseconds);
        if (count > 0) {
            return count + unit.label;
        }
    }

    return "0s";
}

export async function DeletePost(id) {
    const res = await fetch(`https://sketchflow.io/api/posts/${id}`, {
        method: "DELETE",
        headers: {
            'Authorization': localStorage.getItem('jwt')
        }
    });

    await res.json();
    if (res.ok) {
        window.location.reload();
    }
}

export async function UpdateLike(id, callback) {
    const res = await fetch(`https://sketchflow.io/api/posts/${id}/like`, {
        headers: {
            'Authorization': localStorage.getItem('jwt')
        }
    });

    const resData = await res.json();
    if (res.ok) {
        callback(resData.isLiked)
    } else {
        callback(false);
    }
}

export async function UpdateRating(id, callback) {
    const res = await fetch(`https://sketchflow.io/api/posts/${id}/rating`, {
        headers: {
            'Authorization': localStorage.getItem('jwt')
        }
    });

    const resData = await res.json();
    if (res.ok) {
        callback(resData.rating)
    } else {
        callback(0);
    }
}


export async function SubmitLike(id, isLiked, callback) {
    callback(isLiked);
    const res = await fetch(`https://sketchflow.io/api/posts/${id}/like`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('jwt')
        },
        body: JSON.stringify({
            liked: isLiked
        })
    });

    await res.json();
    if (res.ok) {
        callback(isLiked);
    }
}

export async function SubmitRating(id, rating, callback) {
    callback(rating);
    const res = await fetch(`https://sketchflow.io/api/posts/${id}/rating`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('jwt')
        },
        body: JSON.stringify({
            rating: rating
        })
    });

    await res.json();
    if (res.ok) {
        callback(rating);
    }
}

export async function FetchDetails(id) {
    const res = await fetch(`https://sketchflow.io/api/posts/${id}/details`);

    const resData = await res.json();
    if (res.ok) {
        let pfp = ""
        await GetProfilePicture(resData.user_handle, (result) => {
            pfp = result
        })

        return {
            id: resData.id,
            title: resData.title,
            description: resData.description,
            image_url: resData.image_url,
            author: {
                handle: resData.user_handle,
                picture_url: pfp
            },
            comments: resData.total_comments,
            likes: resData.total_likes,
            time: resData.time
        }
    } else {
        return null;
    }
}

async function FetchAnonymousBatch(current, callback) {
    const res = await fetch(`https://sketchflow.io/api/posts/anonymous-batch/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            current: current
        })
    });

    const resData = await res.json();
    if (res.ok) {
        callback(resData.result);
    } else {
        callback([]);
    }
}

async function FetchBatch(handle, current, callback) {
    if (!handle) callback([]);
    const res = await fetch(`https://sketchflow.io/api/posts/batch/`, {
        method: 'POST',
        headers: {
            'Authorization': localStorage.getItem('jwt'),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            current: current
        })
    });

    const resData = await res.json();
    if (res.ok) {
        callback(resData.result);
    } else {
        callback([]);
    }
}


export async function FetchAverageRating(handle, id, callback) {
    const res = await fetch(`https://sketchflow.io/api/posts/${id}/statistics`, {
        headers: {
            'Authorization': localStorage.getItem('jwt')
        }
    });

    const resData = await res.json();
    if (res.ok) {
        callback(resData.result.ratings.average);
    } else {
        callback(0);
    }
}


export const Star = ({index, rating, setRating, style = {}}) => {
    const enabled = index <= rating;

    if (!setRating) {
        return (<>
            {enabled ?
                <FontAwesomeIcon className="post-star disabled active" icon={solid("star")} style={style}/> :
                <FontAwesomeIcon className="post-star disabled" icon={regular("star")} style={style}/>}
        </>)
    }
    return (
        <>
            {enabled ?
                <FontAwesomeIcon className="post-star active" icon={solid("star")} style={style}
                                 onClick={() => setRating(index)}/> :
                <FontAwesomeIcon className="post-star" icon={regular("star")} style={style}
                                 onClick={() => setRating(index)}/>}
        </>
    )
}

export const ImagePopup = ({src, onClose}) => {
    return (<>
        <div onClick={onClose} className={"image-popup-blackout"}/>
        <img onClick={onClose} src={src} alt={"Popup image"} className={"image-popup"}/>
    </>)
}


export const ArtContainer = ({id}) => {
    const {handle, loggedIn} = useAuth();
    const navigate = useNavigate();
    const [details, setDetails] = useState(null);
    const [rating, setRating] = useState(0);
    const [isLiked, setIsLiked] = useState(false)
    const [showOwnerDropDown, setShowOwnerDropDown] = useState(false);
    const [showComments, setShowComments] = useState(false);


    const [truncateDescription, setTruncateDescription] = useState(true);

    const [averageRating, setAverageRating] = useState(0.0);

    const isPostOwner = () => {
        if (!details) return;
        return details.author.handle === handle;
    }

    useEffect(() => {
        if (!handle) return;
        UpdateRating(id, setRating);
        UpdateLike(id, setIsLiked)
    }, [handle])
    useEffect(() => {
        if (typeof id !== "number") return;
        FetchDetails(id).then((res) => setDetails(res));
    }, [id])
    useEffect(() => {
        if (!handle || !isPostOwner()) return;

        FetchAverageRating(handle, id, (avg) => {
            setAverageRating(Math.round(avg * 10) / 10)
        })
    })

    if (typeof id !== "number") {
        return (<></>)
    }
    if (!details) {
        return (<></>)
    }

    const timeString = FormatTimeDifference(details.time, Date.now());

    const maxRating = 5;

    const maxDescLength = 180;
    let description = details.description;
    if (truncateDescription) description = description.substring(0, Math.min(maxDescLength, description.length));
    if (description !== details.description) {
        if (details.description !== "") {
            description += "...";
        } else {
            description = <i>{description}</i>
        }
    }

    return (
        <article className="post-container">
            <div className="post-author-container">
                <Link to={`/user/${details.author.handle}`}>
                    <img className="post-author-image" draggable={false} src={details.author.picture_url} alt=""/>
                </Link>
                <Link to={`/user/${details.author.handle}`}><span
                    className="post-author-name">{details.author.handle}</span>
                </Link>
                <span className="post-timestamp" title={FormatTime(details.time)}>{timeString}</span>
                {isPostOwner() &&
                    <div className={"post-edit-container"}>
                        <DropDown showing={showOwnerDropDown} close={() => setShowOwnerDropDown(false)}>
                            <DropDownItem onClick={() => {
                            }}>
                                <Link to={`/post/${id}`}>
                                    <FontAwesomeIcon icon={solid("pencil")} style={{color: "var(--font-colour)"}}/>
                                    <span style={{color: "var(--font-colour)"}}> Edit</span>
                                </Link>
                            </DropDownItem>
                            <DropDownItem onClick={() => {
                            }}>
                                <Link to={`/post/${id}/analytics`}>
                                    <FontAwesomeIcon icon={solid("eye")} style={{color: "var(--font-colour)"}}/>
                                    <span style={{color: "var(--font-colour)"}}> Analytics</span>
                                </Link>
                            </DropDownItem>
                            <DropDownItem onClick={() => DeletePost(id)}>
                                <FontAwesomeIcon icon={solid("trash")} style={{color: "red"}}/>
                                <span style={{color: "red"}}> Delete</span>
                            </DropDownItem>
                        </DropDown>
                        <button onClick={() => setShowOwnerDropDown(!showOwnerDropDown)} className={"post-edit-button"}>
                            <FontAwesomeIcon icon={solid("ellipsis-vertical")}
                                             style={{color: "var(--font-colour)"}}/>
                        </button>
                    </div>}
            </div>
            <span className="post-title">{details.title}</span>
            <div className="post-content-container">
                <FontAwesomeIcon icon={solid("spinner")} spin
                                 style={{
                                     color: "var(--font-colour)",
                                     marginRight: "4px",
                                     position: "absolute",
                                     zIndex: "0"
                                 }}/>
                <img className="post-content" onClick={() => navigate(`/post/${id}`)} src={details.image_url}
                     draggable={false} alt={""}/>
            </div>

            {loggedIn ?
                <div className={"post-actions-container"}>
                    <div className={"post-action-container like"}>
                        <FontAwesomeIcon onClick={() => {
                            isLiked ? details.likes -= 1 : details.likes += 1
                            SubmitLike(id, !isLiked, setIsLiked)
                            HapticTap()
                        }}
                                         className={`post-action-button heart ${isLiked ? "active" : ""}`}
                                         icon={isLiked ? solid("heart") : regular("heart")}/>
                        <div className={"post-action-label"}>{details.likes}</div>
                    </div>
                    <div className="post-rating-container">
                        {!isPostOwner() ? Array.from({length: maxRating}).map((_, index) => <Star key={index}
                                                                                                  rating={rating}
                                                                                                  index={index + 1}
                                                                                                  setRating={(r) => SubmitRating(id, r, setRating)}/>) :
                            Array.from({length: maxRating}).map((_, index) => <Star key={index} rating={averageRating}
                                                                                    index={index + 1}/>)
                        }
                        <span
                            className={"post-average-rating"}>{(isPostOwner() && typeof averageRating === "number") && `(${averageRating})`}</span>

                    </div>
                    <div className={"post-action-container comment"}>
                        <FontAwesomeIcon onClick={() => navigate(`/post/${id}`)} className="post-action-button comment"
                                         icon={showComments ? solid("comment") : regular("comment")}/>
                        <div className={"post-action-label"}>{details.comments}</div>
                    </div>

                </div>

                :
                <div className={"post-actions-container"}>
                    <div className={"post-action-container like"}>
                        <FontAwesomeIcon onClick={() => navigate('/login')}
                                         className={`post-action-button heart disabled`}
                                         icon={isLiked ? solid("heart") : regular("heart")}/>
                        <div className={"post-action-label"}>{details.likes}</div>
                    </div>
                    <div className="post-rating-container">
                        {Array.from({length: maxRating}).map((_, index) => <Star key={index} rating={0}
                                                                                 index={index + 1}/>)}


                    </div>
                    <div className={"post-action-container comment"}>
                        <FontAwesomeIcon onClick={() => navigate(`/post/${id}`)} className="post-action-button comment"
                                         icon={showComments ? solid("comment") : regular("comment")}/>
                        <div className={"post-action-label"}>{details.comments}</div>
                    </div>
                </div>

            }


            {details.description.length > 0 && <span
                className={`body post-description ${(details.description.length >= maxDescLength) && "oversized"}`}
                onClick={() => setTruncateDescription(!truncateDescription)}>{FormatUserPostText(description)}</span>}
        </article>
    )
}

const Scroller = () => {
    const {handle, loggedIn} = useAuth();

    const [posts, setPosts] = useState([]);
    const [loadingPosts, setLoadingPosts] = useState(false)
    const [finishFeed, setFinishFeed] = useState(false);

    function LoadNewBatch() {
        if (loadingPosts) return;
        setLoadingPosts(true)
        if (!loggedIn) {
            FetchAnonymousBatch(posts, (result) => {
                setPosts([...posts, ...result])
                setLoadingPosts(false)
                if (result.length < 5) {
                    setFinishFeed(true)
                }
            })
        } else {
            FetchBatch(handle, posts, (result) => {
                setPosts([...posts, ...result])
                setLoadingPosts(false)
                if (result.length < 5) {
                    setFinishFeed(true)
                }
            })
        }
    }

    useEffect(() => {
        if (!handle && loggedIn) return;
        LoadNewBatch()
    }, [handle]);

    return (
        <div className={`home-page`} onScroll={() => console.log("e")}>
            <script async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9597847883516380"
                    crossOrigin="anonymous"></script>
            <ins className="adsbygoogle"
                 style={{display: 'block'}}
                 data-ad-format="fluid"
                 data-ad-layout-key="-c4-l+34-66+5b"
                 data-ad-client="ca-pub-9597847883516380"
                 data-ad-slot="3222254939"></ins>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
            {
                posts.map((id) => (<ArtContainer key={id} id={id}/>))
            }
            {loadingPosts ?
                <FontAwesomeIcon spin icon={solid("spinner")}/>
                :
                !finishFeed ?
                    <button className={"secondary-button"} onClick={LoadNewBatch} style={{marginBottom: '15px'}}>
                        <FontAwesomeIcon icon={solid("plus")}/>
                        <span> Load More</span>
                    </button>
                    :
                    <>
                        <FontAwesomeIcon icon={solid("check")}
                                         style={{color: 'var(--font-colour)', height: '3em', marginTop: '20px'}}/>
                        <span className={"subheading"}>
                        You're all caught up!
                    </span>
                        <span className={"upload-info"} style={{marginBottom: '40px'}}>No more posts</span>
                    </>}
        </div>
    )
}

export const HomePage = () => {
    const {setPlainTitle} = useNotifications()
    useEffect(() => {
        setPlainTitle("SketchFlow")
    }, []);

    return (<Scroller/>)
}