import '../stylesheet/settings-page.css'
import React, {createContext, useCallback, useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {useAuth} from "./login-signup-page";
import {useNotifications} from "../components/navbar";
import CloseButton from "../components/close-button";

const SettingsContext = createContext();

export const SettingsProvider = ({children}) => {
    const [theme, setTheme] = useState("light");

    const validThemes = ["light", "dark"]
    useEffect(() => {
        let settings = localStorage.getItem('settings')
        if (settings) {

            settings = JSON.parse(settings);
            if (validThemes.includes(settings.theme)) {
                setTheme(settings.theme)
            } else {
                console.log("setting to light")
                setTheme("light")
                localStorage.setItem('settings', JSON.stringify({theme: "light"}))
            }

        } else {
            localStorage.setItem('settings', JSON.stringify({theme: "light"}))
        }
    }, [])
    useEffect(() => {
        const settings = JSON.parse(localStorage.getItem('settings'))
        settings.theme = theme
        localStorage.setItem('settings', JSON.stringify(settings))
    }, [theme]);

    return (
        <SettingsContext.Provider value={{theme, setTheme}}>
            {children}
        </SettingsContext.Provider>
    );
};

export const useSettings = () => {
    return useContext(SettingsContext);
};

async function RequestCode(email, callback) {
    const res = await fetch(`https://sketchflow.io/api/email_verification_code`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('jwt')
        },
        body: JSON.stringify({
            email: email
        })
    });

    await res.json()
    if (res.ok) {
        callback({error: false, message: "Successfully sent code. Check your inbox."})
    } else {
        callback({error: true, message: "An error occurred when sending the verification code."})
    }
}

async function GetEmail(handle, callback) {
    const res = await fetch(`https://sketchflow.io/api/user/${handle}/email`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('jwt')
        }
    });

    const resData = await res.json();
    if (res.ok) {
        callback(resData.email);
    }
}

async function SubmitEmailChange(handle, email, code, callback) {
    const res = await fetch(`https://sketchflow.io/api/user/${handle}/email`, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('jwt')
        },
        body: JSON.stringify({
            email: email,
            code: Number(code)
        })
    });

    const resData = await res.json()
    if (res.ok) {
        if (resData.error) {
            callback({error: true, message: resData.message})
            return
        }
        callback({error: false, message: "Successfully updated email"})
    }
}

const PasswordChangeDialogue = ({submit, close, passwordError, setPasswordError}) => {
    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    function HandleSubmit(e) {
        e.preventDefault();
        if (confirmPassword !== newPassword) return;
        submit(currentPassword, newPassword)
    }

    return (<>
        <div className={"settings-password-dialogue-blackout"} onClick={close}/>
        <div className={"settings-password-dialogue"}>
            <CloseButton onClose={close}/>
            <div className={"subheading"} style={{width: '100%', marginTop: '30px', textAlign: 'center'}}>Change Password</div>
            <form className={"settings-password-dialogue-form"} onSubmit={HandleSubmit}>
                <input className={"settings-password-input"}
                       type={"password"}
                       onChange={(e) => setCurrentPassword(e.target.value)}
                       placeholder={"Current Password"}/>

                <input className={"settings-password-input"}
                       type={"password"}
                       onChange={(e) => setNewPassword(e.target.value)}
                       placeholder={"New Password"}/>
                <input className={"settings-password-input"}
                       type={"password"}
                       onChange={(e) => setConfirmPassword(e.target.value)}
                       placeholder={"Confirm New Password"}/>

                <button type={"submit"}
                        className={`primary-button settings-password-button ${(currentPassword === "" || newPassword === "" || confirmPassword !== newPassword) && "disabled"}`}>
                    <FontAwesomeIcon icon={solid("lock")}/>
                    <span> Submit</span>
                </button>
                {(passwordError && passwordError.message !== "") &&
                    <div className={`settings-email-message ${passwordError.error ? "error" : "success"}`}>
                        {passwordError.error ?
                            <FontAwesomeIcon icon={solid("warning")}/>
                            :
                            <FontAwesomeIcon icon={solid("check")}/>}
                        <span> {passwordError.message}</span>
                    </div>}
            </form>
        </div>

    </>)
}
export const SettingsPage = () => {
    const {handle, loggedIn} = useAuth();
    const {theme, setTheme} = useSettings()
    const [currentEmail, setCurrentEmail] = useState("");
    const [email, setEmail] = useState("");
    const [emailCode, setEmailCode] = useState("");

    const [codeServerResponse, setCodeServerResponse] = useState({error: false, message: ""});
    const [emailUpdateServerResponse, setEmailUpdateServerResponse] = useState({error: false, message: ""});

    const [isEmailValid, setIsEmailValid] = useState(true);
    const [codeButtonEnabled, setCodeButtonEnabled] = useState(false);
    const {setPlainTitle} = useNotifications()

    const [showPasswordDialogue, setShowPasswordDialogue] = useState(false);

    useEffect(() => {
        setPlainTitle("Settings | SketchFlow")
    }, []);


    useEffect(() => {
        const pattern = /^[^\.\s][\w\-]+(\.[\w\-]+)*@([\w-]+\.)+[\w-]{2,}$/gm;
        setIsEmailValid(pattern.test(email))
        if (email !== "") {
            setCodeButtonEnabled(true)
        }
    }, [email])

    useEffect(() => {
        if (handle === "") return;
        GetEmail(handle, (email) => {
            setCurrentEmail(email)
        })
    }, [handle])

    function HandleEmailSubmit(e) {
        e.preventDefault()
        if (email === "" || emailCode === "") return;
        SubmitEmailChange(handle, email, emailCode, setEmailUpdateServerResponse)
    }

    return (<div className={"settings-page"}>
        <div className={"settings-container"}>
            <span className="heading">Settings</span>
            <span className={"subheading"} style={{marginTop: '20px'}}>Appearance</span>
            <select className={"settings-theme-select"} value={theme}
                    onChange={(e) => setTheme(e.target.value)}>
                <option value="light">Light</option>
                <option value="dark">Dark</option>
            </select>
            {loggedIn && <><span className={"subheading"} style={{marginTop: '20px'}}>Email</span>
                {currentEmail !== "" && <span className={"settings-email"}>Current Email: {currentEmail}</span>}
                <form className={"settings-email-container"} onSubmit={HandleEmailSubmit}>
                    <div className={"settings-email-row"}>
                        <input className={"settings-email-input"}
                               type={"text"}
                               onChange={(e) => setEmail(e.target.value)}
                               placeholder={"Update Email"}/>
                        <button onClick={() => {
                            if (codeButtonEnabled && isEmailValid) {
                                RequestCode(email, setCodeServerResponse);
                                setCodeButtonEnabled(false)
                            }
                        }}
                                className={`primary-button settings-email-button ${(!codeButtonEnabled || !isEmailValid) && "disabled"}`}>
                            <FontAwesomeIcon icon={solid("paper-plane")}/>
                            <span>{(codeButtonEnabled) ? " Verify" : (isEmailValid ? " Sent!" : " Verify")}</span>
                        </button>
                    </div>
                    {(codeServerResponse.message !== "") && <div className={`settings-email-message ${codeServerResponse.error ? "error" : "success"}`}>
                    {codeServerResponse.error ?
                        <FontAwesomeIcon icon={solid("warning")}/>
                        :
                        <FontAwesomeIcon icon={solid("check")}/>}
                    <span> {codeServerResponse.message}</span>
                </div>}
                <div className={"settings-email-row"}>
                    <input className={"settings-email-input"}
                           onChange={(e) => setEmailCode(e.target.value)}
                           placeholder={"Verification Code"}/>
                    <button type={"submit"} className={`primary-button settings-email-button ${(email === "" || emailCode === "") && "disabled"}`}>
                        <FontAwesomeIcon icon={solid("check")}/>
                        <span> Update</span>
                    </button>
                </div>
                {(emailUpdateServerResponse.message !== "") && <div className={`settings-email-message ${emailUpdateServerResponse.error ? "error" : "success"}`}>
                    {emailUpdateServerResponse.error ?
                        <FontAwesomeIcon icon={solid("warning")}/>
                        :
                        <FontAwesomeIcon icon={solid("check")}/>}
                    <span> {emailUpdateServerResponse.message}</span>
                </div>}
            </form></>}

            {/*{loggedIn && <><span className={"subheading"} style={{marginTop: '20px'}}>Password</span>*/}
            {/*    <button className={"secondary-button settings-email-button"} onClick={() => setShowPasswordDialogue(true)}>*/}
            {/*        <FontAwesomeIcon icon={solid("lock")}/>*/}
            {/*        <span> Change</span>*/}
            {/*    </button>*/}
            {/*</>}*/}
            {/*{showPasswordDialogue &&*/}
            {/*<PasswordChangeDialogue submit={() => console.log("e")} close={() => setShowPasswordDialogue(false)}/>}*/}
        </div>
    </div>)
}