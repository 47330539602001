import "./../stylesheet/create-page.css"
import React, {useCallback, useEffect, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FormActionButton, FormError, FormFieldSection} from "./../components/form-essentials";
import {useAuth} from "./login-signup-page";
import {Navigate, useNavigate} from "react-router";
import {useNotifications} from "../components/navbar";

const DetailsPage = ({image, error, removeError, callback}) => {
    const {setPlainTitle} = useNotifications()
    useEffect(() => {
        setPlainTitle("Create | SketchFlow")
    }, []);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [changed, setChanged] = useState(false)
    const [imageUrl, setImageUrl] = useState("")

    let disabled = false;
    const errors = {title: "", description: ""};
    if (submitted) {
        if (!title) {
            disabled = true;
            errors.title = "This is a required field.";
        }
    } else {
        if (!title) disabled = true;
    }
    if (!errors.title && title.length > 100) {
        disabled = true;
        errors.title = "Must be less than 100 characters.";
    }
    if (!errors.description && description.length > 1000) {
        disabled = true;
        errors.description = "Must be less than 1000 characters.";
    }

    useEffect(() => {
        setImageUrl(URL.createObjectURL(image))
    }, []);

    useEffect(() => {
        if (title.length > 0) setChanged(true)
    }, [title, description]);

    return (
        <div className="create-container details-container">
            <div style={{display: "flex", alignItems: "center"}}>
                <FontAwesomeIcon icon={solid("pen-to-square")} style={{color: "var(--font-colour)", marginRight: "6px"}}/>
                <span className="heading"> Post Details</span>
            </div>
            <span className="body">{image.name}</span>
            <img src={imageUrl} draggable={false}
                 className="create-image-preview"/>

            <FormFieldSection heading="Title" id="post" type="text" onChange={setTitle} error={errors.title}
                              required={true}/>
            <FormFieldSection heading="Description" id="description" type="text"
                              onChange={setDescription} paragraph={true} error={errors.description}/>
            <FormActionButton onClick={() => {
                callback(title, description);
                setSubmitted(true)
                removeError()
            }} disabled={disabled || submitted}>
                {submitted ? <>
                        <FontAwesomeIcon icon={solid("spinner")} spin/>
                        <span> Uploading</span></>
                :
                <>
                    <FontAwesomeIcon icon={solid("check")}/>
                    <span> Finish</span>
                </>}
            </FormActionButton>
            {(error !== "" && !changed) && <div className={"field-error-message"}>
                <FontAwesomeIcon icon={solid("warning")} style={{color: "inherit"}}/>
                <span> {error}</span>
            </div>}
        </div>
    )
}

const UploadPage = ({callback, error}) => {
    const {setPlainTitle} = useNotifications()
    useEffect(() => {
        setPlainTitle("Upload | SketchFlow")
    }, []);

    const onDrop = useCallback((files) => {
        callback(files[0]);
    }, [callback]);


    const {getRootProps, getInputProps, isDragActive, open} = useDropzone({
        onDrop,
        noClick: true,
        accept: {'image/*': []},
        maxFiles: 1,
        multiple: false,
        maxSize: (8 * 1024 * 1024)
    });

    return (
        <div {...getRootProps({className: `create-container upload-container ${isDragActive ? "selected" : ""}`})}>
            <input {...getInputProps()}/>
            <FontAwesomeIcon icon={solid("cloud-arrow-up")} style={{color: "var(--font-colour)", height: "80px"}}/>

            <span className="heading"
                  style={{marginTop: "10px", textAlign: "center", marginBottom: "3px"}}>Upload Image</span>
            <span className="body" style={{textAlign: "center"}}>Drag and drop an image</span>
            <span className="body" style={{textAlign: "center", fontWeight: "600", marginTop: "8px"}}>OR</span>

            <button className="primary-button" style={{marginTop: "15px"}} onClick={open}>
                <FontAwesomeIcon icon={solid("image")} style={{color: "var(--white)", marginRight: "4px"}}/>
                <span> Select Image</span>
            </button>
            <FormError body={error}/>

            <span className="body"
                  style={{textAlign: "center", fontSize: "0.8em", fontWeight: "300", marginTop: "8px"}}>Max dimensions 2048 x 2048, max size 8 MB.</span>
        </div>
    )
}

export const CreatePage = () => {
    const {handle, loggedIn} = useAuth();
    const [file, setFile] = useState(null);
    const [page, setPage] = useState(0);
    const navigate = useNavigate()

    const [serverError, setServerError] = useState("");

    const {setPlainTitle} = useNotifications()
    useEffect(() => {
        setPlainTitle("Create | SketchFlow")
    }, []);

    if (!loggedIn) {
        return (<Navigate to={"/login"}/>)
    }

    const HandlePublish = async (title, description) => {
        const data = new FormData();
        data.append('file', file);
        data.append('title', title);
        data.append('author', handle);
        data.append('description', description);

        try {
            const res = await fetch("https://sketchflow.io/api/posts/create", {
                method: 'POST',
                headers: {
                    'Authorization': localStorage.getItem('jwt')
                },
                body: data
            })

            const resData = await res.json();
            if (res.ok) {
                setServerError("");
                navigate(`/user/${handle}`)
            } else {
                setServerError(resData.message);
            }
        } catch (err) {
            setServerError("An internal error occurred");
        }
    }

    const PageComponent = () => {
        switch (page) {
            case 0:
                return (<UploadPage callback={(newFile) => {
                    setFile(newFile);
                    setPage(1)
                }} error={serverError}/>)
            case 1:
                return (<DetailsPage image={file} callback={HandlePublish} error={serverError} removeError={() => {
                    setServerError("")
                }}/>)
            default:
                return (<div/>)
        }
    }

    return (
        <div style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "calc(16px + 58px)"
        }}>
            <PageComponent/>
        </div>
    )
}